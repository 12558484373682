module.exports = {
    iphone:'móvel',
    lang:'EN',
    langc:'中文',
    lange:'English',
    langh:'繁体',
    name:'SMARTGEN (ZHENGZHOU) TECHNOLOGY CO., LTD.',
    jieshao:'Em 1998, iniciamos a pesquisa e desenvolvimento de controladores de grupos geradores e a empresa SmartGen foi registrada e estabelecida em 2003. SmartGen é uma empresa de modernização tecnológica, com foco em sistema de controle inteligente para motor, gerador e fonte de alimentação dupla, bem como produtos relacionados, e concentrando-se em design, pesquisa e desenvolvimento, fabricação, venda e serviços. Os produtos são amplamente utilizados na indústria militar, mineração e exploração de petróleo, data centers, navios, edifícios inteligentes, bancos, hospitais, telecomunicações, infraestrutura e outros campos. Temos mais de 300 funcionários e vendemos produtos em todo o mundo. Mais de 30% dos funcionários são dedicados à P&D, buscando explorar novas soluções inteligentes para melhorar o desempenho geral dos equipamentos da indústria global.',
    manualInChinese:'Manual Chinês',
    englishManual:'Manual Inglês',
    testingSoftware:'Software',
    typicalApplicationDiagram:'Aplicação Típica',
    DModelDiagram:'Diagrama de Modelo 3D',
    chineseSolution:'Chinese Solution',
    englishProgram:'English Solution',
    aSinglePageInChinese:'Página Única del Producto',
    ProductSinglePage:'产品单页',
    chineseCommunicationProtocol:'Protocolo de Comunicação Chinês',
    englishCommunicationProtocol:'Protocolo de Comunicação Inglês',
    shengming:'Divisão Global de Negócios, departamento de vendas no exterior 1, vendas no Oriente Médio, agente, divisão de controle de gerador, escritório, divisão de controle de ATS, Divisão de Controladores para Geradores Marítimos, Divisão de Controladores para Motor',
    inquire:'Consulta',
    notAvailable:'Nenhum conteúdo de pesquisa',
    nosousuo:"Não é possível pesquisar o que você deseja",
    BUG:'quero feedback',
    loginerror:'Please log in first, and then check',
    notLogIn:'Not logged in',
    logIn:'logon',
    close:'close',
    download:'Download',
    theFreeCall:'National toll free',
    dataType:'Tipo de Dados',
    all:'Todos',
    getCode:'Get verification code',
    confirm:'Confirm',
    cancel:'Cancel',
    login:{
        zhineng:'Torna o controlo mais inteligente!',
        dizao:'Crie sabedoria centenária e seja a marca de parceiros mais confiável',
        zhinengxin:'',
        fendou:'',
        welcome:'Login',
        userName:'Phone or E-mail',
        password:'Senha',
        code:'Captcha',
        logIn:'Logon',
        register:'Registro',
        codelogin:'Login com código de verificação',
        userlogin:'Login with account',
        forgetPassword:'Esqueci a senha',
        rememberPassword:'Lembrar senha',
        tishi:'Kindly reminder: It is common with the APP account of SmartGen products',
        usermsg : 'Username cannot be empty',
        pwdmsg:'Senha cannot be empty',
        codemsg: 'Captcha cannot be empty',
        notnull:'Cannot be empty',
    },
    personal:{
        title:'My Center',
        change:'Change',
        iphone:'Phone number',
        notBound:'Not linked',
        toBind:'link',
        email:'Email address',
        weititle:'It is not linked, you can link to subscribe new products information',
        dyyj:'Subscribe email',
        qxdy:'Cancel subscription',
        changePassword:'Change password',
        loginPassword:'Change your login password',
        closeAnAccount:'Cancel the account',
        deldata:'Delete your account and related data permanently',
        logout:'Cancel',
        changeNickname:'Change nickname',
        replaceAPhoneNumber:'Change phone number',
        codeinput:'Please enter verification code',
        replaceTheEmail:'Change email',
        emailinput:'Please enter your email',
        oldpassword:'Please enter your original password',
        newpassword:'Please enter your new password',
        confirmpassword:'Confirm your new password again',
        modifySuccessfully:'Modify successfully',
        iphonemsg:'Please enter correct phone format',
        emailmsg:'Please enter correct email format'
    },
    layout: {
        search: 'Pesquisa',
        searchplaceholder :'Please input content',
        home:'Índice',
        productcenter:'Produtos',
        productClassification:'PRODUCTOS',
        Viewall:'Check all',
        solution:'Soluções',
        application:'Casos de Aplicações',
        technical:'Suporte técnico',
        download:'Download',
        model:'Amostra do produto',
        ECUfaultcode:'Códigos de falha da ECU',
        FAQ:'FAQS',
        Productfeedback:'Feedback do usuário',
        about:'Sobre nós',
        wenhua:'Culture',
        news:'News',
        honor:'Honors',
        logo:'Marca comercial',
        join:'Join Us',
        contact:'Contate-nos',
        store:'Shop',
        cloudplatform:'Cloud Monitoring Platform',
        register:'Register',
        login:'Conecte-se',
        userName:'Username',
        exit:'Quit',
        followUs:'Please focus on us',
        chineseWeChatServiceNumber:'Chinese WeChat Service Account',
        englishWeChatServiceNumber:'English WeChat Service Account',
        productAPP:'Aplicativo de produtos SmartGen',
        WeMedia:'SmartGen Mídia',
        sumaitong:'AliExpress Mall',
        taobao:'Taobao Mall',
        ali:'Alibaba Mall',
        a1688:'1688 Mall',
        address:'No.28 Jinsuo Road, cidade de Zhengzhou, província de Henan, China',
        LinkedIn:'LinkedIn platform of SmartGen',
        weibo:'Blog',
        weibourl:'http://blog.smartgen.cn',
        boke:'Facebook',
        bokeurl:'https://www.facebook.com/smartgen98318',
        tencentVideo:'Linkedin',
        tencenturl:'https://www.linkedin.com/company/13719141/admin/',
        Youku:'Twitter',
        Youkuurl:'https://twitter.com/lcsmartgen',
        Tudou:'Odnoklassniki',
        Tudouurl:'https://ok.ru/zhengzhou.smartgen',
        DocIn:'VK',
        DocInurl:'https://vk.com/smartgen980318',
        baidu:'Blogger',
        baiduurl:'http://smartgenblog.blogspot.com/',
        Wordpress:'Wordpress',
        Wordpressurl:'https://smartgenblog.wordpress.com/',
        Tumblr:'Tumblr',
        Tumblrurl:'https://smartgen.tumblr.com/',
        Naver:'Naver',
        Naverurl:'https://blog.naver.com/smartgen980318',
        Site123:'Site123',
        Site123url:'https://5e1ec07d59538.site123.me/',
        YouTube:'YouTube',
        YouTubeurl:'https://www.youtube.com/channel/UCxLKCBUbqLT5mT93j9mzbjQ?view_as=subscriber',
        Pinterest:'Pinterest',
        Pinteresturl:'https://www.pinterest.com/smartgen980318/'
    },
    index:{
        NewProducts:'Novos Produtos',
        xuanze:'Por que escolher a SmartGen',
        dizao:'Construindo uma SmartGen como a marca mais confiável para os parceiros',
        zishen:'Experiência na indústria',
        lingyu:'Primeira geração de desenvolvimento de produtos em 1998, mais de 20 anos de história, uma das primeiras empresas da China envolvida no desenvolvimento e produção de controladores para grupos geradores e transferência entre duas fontes de energia.',
        yingyong:'Amplos campos de aplicação',
        yytext:'Os produtos podem ser aplicados em defesa nacional, data centers, infraestrutura, navios, energia híbrida, micro rede, máquinas de engenharia, etc.',
        rongyu:'Múltiplas certificações de Qualidade',
        tixi:'Os produtos possuem certificações de qualidade de consagradas agências certificadoras nacionais (China) e internacionais, incluindo CCC, CE, CCS, BV, UL, RS, certificação militar, certificação de laboratório MTU da Alemanha, etc.',
        fengfu:'Produtos Diversificados',
        chanpins:'Os produtos incluem Controlador para Grupo Gerador, Controlador ATSE, Controlador Diesel Marítimo, Controlador de Motor, Carregador de Baterias, ATS, Módulo de Monitoramento em Núvem, Módulos de Expansão, etc.',
        yanfa:'Equipe de Pesquisa e Desenvolvimento Capacitada',
        yongyou:'A equipe profissional de P&D corresponde à 30% do total de funcionários da empresa, que continua investindo fortemente em P&D, fabricação inteligente e laboratório próprio e padrão para o setor eletrônico, totalizando quase 600 patentes.',
        zhuanye:'Serviço de Vendas Profissional',
        zhuanyes:'A empresa tem um serviço de vendas profissionalizado e qualificado sistema de serviço pós-venda. Cada região possui um especialista de vendas permanentemente alocado na área local, responsável pela pré-venda e pós-venda local.',
        recentNews:'Notícias'
    },
    user:{
        account:'Conta',
        verificationCode:'Captcha',
        newPassword:'The new password',
        confirmPassword:'Confirmar Senha',
        retrievePassword:'Get back Password',
        title:'The password is set successfully, please log in again!',
        futitle:'Please remember the new account password',
        loginImmediately:'Log in now',
        backHomepage:'Back to homepage',
        mimamsg:'The length of the user password must be between 5 and 20',
        reenter:'Please input the password again',
        pwdb:'Two input password are inconsistent'
    },
    reg:{
        mailbox:'E-mail',
        password:'password',
        confirmPassword:'Confirmar Senha',
        name:'Nome',
        mobilePhone:'Telefone',
        companyName:'Empresa',
        companyIndustry:'Segmento da Empresa',
        state:'País',
        address:'ADD',
        register:'register',
        registeredSuccessfully:'Registered successfully',
        rememberAccount:'Please remember the account password',
        namemsg:'The nickname of user cannot be empty',
        passwordmsg:'The password of user cannot be empty',
        passwordmsg2:'User password length must be between 5 and 20',
        phonemsg:'Mobile phone number cannot be empty',
        phonemsg2:'Please enter the correct mobile phone number',
        addressmsg:'Address cannot be empty',
        emtail:'Please enter the correct mailbox format',
        reenter:'Please enter password again',
        reenter:'Please input the password again',
        inconformity:'Two input password are inconsistent'
    },
    search:{
        searchResult:'Search result',
        inquire:'Inquire',
        search:'Search',
        dedao:'The results',
        tiao:'Strip',
        all:'whole',
        socialRecruitment:'Social recruitment',
        mobilePhone:'Phone',
        mailbox:'E-MAIL',
        anlititle:'SmartGen cases'
    },
    project:{
        releaseTime:'Release time',
        all:'Todos'
    },
    scheme:{
        planToDownload:'Download da solução',
        solutionOverview:'Program Overview',
        shipin:'Viedo',
        relatedCases:'Casos Relacionados',
        relatedProducts:'Produtos Relacionados'
    },
    product:{
        problemFeedback:'Problem Feedback',
        productOverview:'Visão Geral do Produto',
        technicalParameters:'Parâmetros Técnicos',
        shipin:'Viedo',
        ziliao:'Download de dados',
        relatedCases:'Related Cases',
        relevantSolutions:'Related Solutions',
        versionNumber:'Versão',
        downloadLink:'Link de Download',
        WorkingPowerRange:'Faixa de potência de trabalho',
        OverallDimension:'Dimensão global',
        InstallationDimension:'Dimensão da instalação',
        WorkingTemperature:'temperatura de trabalho',
        ProductReferenceFont:'Fuente de referencia del producto',
        weight:'peso'
    },
    productinfo:{
        series:'Series'
    },
    ecu:{
        brand:'Brand',
        failureCause:'Fault Cause',
        reactionTitle:'Fault Response',
        ansTitle:'Corrective Action'
    },
    fankui:{
        productModel:'Product Model',
        productModelmsg:'Product model cannot be empty',
        type:'Tipo',
        typeMsg:'Product type cannot be empty',
        function:'Funções',
        facade:'Aparências',
        software:' Software',
        hardware:'Hardwares',
        else:'Outros',
        describe:'Description',
        describemsg:'Problem description cannot be empty',
        improvementSuggestions:'Sugestões',
        improvementSuggestionsmsg:'Improvement suggestion cannot be empty',
        mailmsg:'E-mail can not be empty',
        submit:'Submit',
        operateSuccessfully:'Operation succeeded'
    },
    wenti:{
        problemDescription:'Question Description',
        questionAnswering:'Question Solving',
    },
    ziliao:{
        model:'Type',
        applicationTool:'Ferramenta de Aplicação',
        name:'Nome',
        desc:'Descrição do Produto',
        dataDownload:'Download',
        ziliaoDownload:'Download de dados',
        efiDrawings:'Drawing Download'
    },
    jiaru:{
        welfare:'SmartGen welfare',
        jobVacancy:'Job Vacancy',
        Department:'Recruitment department',
        workingPlace:'Place of work',
        Zhengzhou:'Zhengzhou',
        releaseDate:'Release date',
        recruitmentDetails:'Recruitment details',
        salaryAndWelfare:'Compensation and benefits',
        salaryAndWelfareinfo:'Five social insurance and one housing fund and supplementary commercial insurance<br/>Five social insurance and one housing fund and supplementary commercial insurance<br/>Free staff restaurant',
        paidVacation:'Paid holiday',
        paidVacationinfo:'National statutory holidays<br/>Marital leave, maternity leave, breastfeeding leave, paternity leave, bereavement leave, sick leave and annual leave<br/>Give necessary care to employees in case of "childbirth or illness"',
        holidayGift:'Holiday Gifts',
        holidayGiftinfo:'Festival benefits such as Dragon Boat Festival, Mid-Autumn Festival and Spring Festival<br/>Every quarterly daily necessities benefits<br/>Birthday benefits',
        culturalActivity:'Cultural activities',
        culturalActivityinfo:'Company-level activities: anniversary, beer festival, employee family day, annual meeting, etc.<br/>Department-level activities: irregular dinners, tourism, group building, etc.<br/>Associations: Loho Association, Reading Association, Public Welfare Association, Business Etiquette Association',
        talentCultivation:'Personnel Training',
        talentCultivationinfo:'Go out and invite in learning opportunities<br/>Technology, marketing, production, management and other multi-channel career development paths<br/>Excellent Staff Award, Innovation and Improvement Award, Education Promotion Award and Vocational Skills Promotion Award',
        employeeCare:'Employee Care',
        employeeCareinfo:'Occupational health examination<br/>Physical examination of the whole staff<br/>Female employees & special care for employees over 40 years old',
    },
    guanyu:{
        corporateMission:'Missão da empresa:',
        control:' Tornar o controle mais inteligente.',
        spirit:'Visão Empresarial:',
        spiritconetnt:'Construir uma SmartGen como a marca mais confiável para os parceiros.',
        vision:'Valores da empresa:',
        visionconetnt:'Inovação, Foco, Integridade, Responsabilidade, Ganho-Ganho.',
        value:'Espírito Empresarial:',
        valueconetnt:'Melhoria contínua, trabalho em equipe cria valores.',
        m:'',
        statement:'Declaração Ambiental',
        statementcontent:'Por meio de inovação tecnológica, seguir rigorosamente ISO14001 e outros padrões, implementar controle e gerenciamento completos, determinamos ser uma verdadeira empresa verde.',
        developmentHistory:'Development history',
        contactUs:'Contact us',
        phone:'TEL',
        theForeignTradeInAStraightLine:'Foreign Trade Straight Line',
        fax:'FAX',
        pbx:'PBX',
        postcode:'Postcode',
        mailbox:'E-mail',
        QQ:'QQ Technical Communication group',
        controlField:'',
        controlField2:'Líder e definidor de padrões no controle do grupo gerador',
        controlFieldy:'23 year',
        agent:'',
        agent2:'Escritórios e agentes na China e no exterior',
        agenty:'More than 50',
        patent:'',
        patent2:'Patents',
        patenty:'398 items',
        user:'',
        user2:'30% dos funcionários contribuem para pesquisar e desenvolver',
        usery:'More than 300 people',
        annualOutput:'',
        annualOutput2:'Uma produção anual de mais de 500.000 unidades',
        annualOutputy:'500,000 units',
        informatization:'',
        informatization2:'Estrutura física total: 50.000 m2',
        informatizationy:'Industry 4.0',
        researchAndDevelopment:'Força de P&D',
        researchAndDevelopment2:'Equipe profissional de P&D ocupa 30% dos funcionários contribuem para explorar novas soluções inteligentes',
        smart:'Manufatura Inteligente',
        smart2:'Integração perfeita e interação de dados de PLM, MES, WMS e outras informações do sistema com REP como centro de dados',
        quality:'Qualidade garantida',
        quality2:'Uma das principais bases de teste de produtos da indústria, o produto foi certificado sucessivamente pela CCC, CE, UL, CCS, BV, etc.',
        market:'Equipe de vendas',
        market2:'Os vendedores SmartGen são todos especialistas que trabalham na indústria há muitos anos e podem fornecer de forma independente serviços pré-venda, vendas e pós-venda profissional e eficiente',
        laochang:'Jin Suo Lu factory',
        laochangaddress:'No. 28, Jinsuo Road, Zhengzhou high tech Development Zone, Henan Province, China',
        xinchang:'Tian Jian Hu factory',
        xinchangaddress:'No.28 Xuemei Road, Zhengzhou high tech Development Zone, Henhan Province, China',
    },
    biaoshi:{
        title:'O significado de SmartGen',
        content:'SmartGen é nossa marca registrada em inglês. "Smart" significa habilidoso, esperto e inteligente. "Gen" é a abreviação de Genset. SmartGen significa tornar o Genset mais inteligente e humanizado para servir melhor os seres humanos.',
        standardColors:'STANDARD COLORS',
        brandStandardColor:'Brand standard color',
        levels:'The standard color order',
        auxiliaryColor:'Auxiliary color',
        fuzhu:'In order to ensure the standard color visual unity and coordination in the extended application, according to the different application materials and actual production needs, choose more coordinated with standard color can match',
        mascot:'Mascote',
        jxyy:'Baseado nas características do border collie, por ser esperto, habilidoso e inteligente, o nome Smart representa essas características e corresponde à marca “SmartGen”.',
        annotation:'Significado',
        name:'Chame-o de Smart, pois o protótipo vem de border collie, implicando espírito inteligente.',
        name2:'Com a direção sempre em frente, orelhas para cima, o Smart está cheio de vigor e caminhando para um futuro próspero.',
        name3:'Os olhos de Smart são brilhantes e a boca está sempre levantando um sorriso. O Smart é cheio de confiança e está imbuído de energia positiva.',
        logo:'Download de logotipos SmartGen',
        Download:'Download the document'
    },
    lianxi:{
        placeholder:'',
        companyName:'company name',
        theContact:'Contact',
        website:'Website',
        site:'Endereço'
    },
    seo:{
        title:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        keywords:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        description:'SmartGen is a technological modernization enterprise, focusing on intelligent control system for engine, generator and dual power supply, as well as related products, and concentrating on design, research and development, manufacture, sale and services.Products are widely used in military industry, mining and oil exploration, data centers, ships, intelligent buildings, banks, hospitals, telecommunications, infrastructure and other fields.',
        indextitle:'SmartGen-Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        indexkeywords:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module, Intelligent Generator Controller,ASM controller,AMF controller,Synchronization controller,Parallel controller,Load share Controller,Lighting tower controller,ATSE,ATS,Power Management System,Pump controller,Fire pump controller,Irrigation controller,Air pump controller,Jacket Heater,Engine Water Heater,Cloud Monitoring Module',
        indexdescription:'SmartGen is a technological modernization enterprise, focusing on intelligent control system for engine, generator and dual power supply, as well as related products, and concentrating on design, research and development, manufacture, sale and services.Products are widely used in military industry, mining and oil exploration, data centers, ships, intelligent buildings, banks, hospitals, telecommunications, infrastructure and other fields.',
        schemetitle:'Solutions - Genset Control Solutions,ATSE Solutions,Marine Control Solutions,Engine Control Solutions ',
        schemekeywords:'Parallel Solution,Lighting Tower Set Solution,Solution for Telecom Base Station,High Voltage Unit Solution,Black Start Solution,ATS Switching Solution,Synchronization and Load Share,Ship Power SolutionPump Unit Solution',
        scheme:'SmartGen striving for Genset Control Solutions,ATSE Solutions,Marine Control Solutions,Engine Control Solutions  to improve the overall performance of global industry equipment.'
    },
    pinglun:{
        fabiaopinglun:'Comments',
        shuodianshenme:'Say something……',
        quanbupinglun:'All comments',
        huifu:'reply',
        pinglun:'comment'
    }
}