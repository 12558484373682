import en from './en';
import zh from './zh';
import hk from './hk';
import pt from './pt';
import es from './es';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import hkLocale from 'element-ui/lib/locale/lang/zh-TW';
import ptLocale from 'element-ui/lib/locale/lang/pt';
import esLocale from 'element-ui/lib/locale/lang/es';

const messages = {
  en: {
    ...en,
    ...enLocale
  },
  zh: {
    ...zh,
    ...zhLocale
  },
  hk: {
    ...hk,
    ...hkLocale
  },
  pt: {
    ...pt,
    ...ptLocale
  },
  es: {
    ...es,
    ...esLocale
  }
};
export default messages;