module.exports = {
    iphone:'手机端',
    lang:'CN',
    langc:'中文',
    lange:'English',
    langh:'繁体',
    name:'郑州众智科技股份有限公司（股票代码：301361）',
    jieshao:'众智科技始建于1998年，深交所创业板（股票代码301361）上市企业，主要从事发动机自动控制系统、发电机和发电机组自动控制系统、低压配电自动控制系统、新能源自动控制系统等相关自动化产品的研发、生产、销售和服务。公司产品广泛应用于矿山石油开采、数据中心、船舶、智能大厦、银行、医院、工厂、电信、基建等领域，业务范围遍布全球。公司是国家专精特新“小巨人”企业，是高成长性、高新技术企业，也是技术密集型现代化企业。自成立以来，每年都会在产品研发、标准试验室、数字工厂方面投入大量资金，以保持公司在国内国际市场中的领先地位。我们拥有数百项专利，有超过30%的员工致力于研发工作，负责探索全新的智能化方案来提升全球行业设备的整体性能。我们将一如既往地不断创新与自我超越，持续提高核心竞争力，提升企业和员工价值，打造优质、安全、绿色、低碳等可持续发展的完整产业生态链，以优质的业绩回报客户、回报股东、回报员工、回报社会！',
    manualInChinese:'中文说明书',
    englishManual:'英文说明书',
    testingSoftware:'测试软件',
    typicalApplicationDiagram:'典型应用图',
    DModelDiagram:'三维模型图',
    chineseSolution:'中文方案',
    englishProgram:'英文方案',
    aSinglePageInChinese:'中文单页',
    ProductSinglePage:'产品单页',
    chineseCommunicationProtocol:'中文通讯协议',
    englishCommunicationProtocol:'英文通讯协议',
    shengming:'声明：众智科技采用国内直销、大陆以外指定代理商的模式销售产品，所有产品均由中国·郑州工厂直接发货',
    inquire:'查询',
    notAvailable:'暂无搜索内容',
    nosousuo:'搜索不到想要的内容',
    BUG:'我要反馈',
    loginerror:'请先登陆后再查询',
    notLogIn:'未登录',
    logIn:'登录',
    close:'关闭',
    download:'下载',
    theFreeCall:'全国免费电话',
    dataType:'资料类型',
    all:'全部',
    getCode:'获取验证码',
    confirm:'确定',
    cancel:'取消',
    login:{
        zhineng:'让控制更智能！',
        dizao:'缔造百年众智，做合作伙伴最值得信赖的品牌',
        zhinengxin:'为世界上每一台设备都打造一颗更加智能的心',
        fendou:'为满足合作伙伴对产品多样化和高品质的追求而持续奋斗',
        welcome:'欢迎登录',
        userName:'手机号/邮箱',
        password:'密码',
        code:'验证码',
        logIn:'登录',
        register:'注册',
        codelogin:'验证码登录',
        userlogin:'账号登录',
        forgetPassword:'忘记密码',
        rememberPassword:'记住密码',
        tishi:'温馨提示：与智云平台账号通用',
        usermsg : '用户名不能为空',
        pwdmsg:'密码不能为空',
        codemsg: '验证码不能为空',
        notnull:'不能为空',
    },
    personal:{
        title:'个人中心',
        change:'更改',
        iphone:'手机号',
        notBound:'未绑定',
        toBind:'去绑定',
        email:'邮箱地址',
        weititle:'未绑定，绑定后可订阅新品推荐',
        dyyj:'订阅邮件',
        qxdy:'取消订阅',
        changePassword:'修改密码',
        loginPassword:'修改您的登录密码',
        closeAnAccount:'注销账号',
        deldata:'永久删除您的账号及相关数据',
        logout:'注销',
        changeNickname:'修改昵称',
        replaceAPhoneNumber:'更换手机号',
        codeinput:'请输入验证码',
        replaceTheEmail:'更换邮箱',
        emailinput:'请输入您的邮箱',
        oldpassword:'请输入您的原始密码',
        newpassword:'请输入新密码',
        confirmpassword:'再次确认新密码',
        modifySuccessfully:'修改成功',
        iphonemsg:'请输入正确的手机格式',
        emailmsg:'请输入正确的邮箱格式'
    },
    layout: {
        search: '搜索',
        searchplaceholder :'请输入内容',
        home:'首页',
        productcenter:'产品中心',
        productClassification:'产品分类',
        Viewall:'查看全部',
        solution:'解决方案',
        application:'应用案例',
        technical:'技术支持',
        download:'资料下载',
        model:'选型样本',
        ECUfaultcode:'ECU故障代码',
        FAQ:'常见问题解答',
        Productfeedback:'用户反馈',
        about:'关于众智',
        wenhua:'众智文化',
        news:'企业新闻',
        honor:'资质荣誉',
        logo:'企业标识',
        join:'加入众智',
        contact:'联系众智',
        store:'商城',
        cloudplatform:'智云平台',
        register:'注册',
        login:'登录',
        userName:'用户名',
        exit:'退出',
        followUs:'关注我们',
        chineseWeChatServiceNumber:'众智中文微信订阅号',
        englishWeChatServiceNumber:'众智英文微信服务号',
        productAPP:'众智产品APP',
        WeMedia:'众智自媒体',
        sumaitong:'京东商城',
        taobao:'淘宝商城',
        ali:'阿里国际站',
        a1688:'1688',
        address:'中国·河南省郑州市高新区雪梅街28号',
        weibo:'众智微博平台',
        weibourl:'https://weibo.com/smartgenwb',
        boke:'众智博客平台',
        bokeurl:'http://blog.sina.com.cn/u/2490663307',
        tencentVideo:'',
        tencenturl:'https://v.qq.com/s/videoplus/307236628',
        Youku:'众智优酷视频',
        Youkuurl:'',
        Tudou:'',
        Tudouurl:'http://id.tudou.com/smartgen',
        DocIn:'众智豆丁文库',
        DocInurl:'http://www.docin.com/smartgen',
        baidu:'众智百度文库',
        baiduurl:'https://cuttlefish.baidu.com/shop/a20ffe4733687e21af45a945',
        develop:'可持续发展',
        xuanchuan:'宣传片',
        quanjing:'全景展厅'
    },
    index:{
        NewProducts:'新品推荐',
        xuanze:'为什么选择众智',
        dizao:'缔造百年众智，做合作伙伴最值得信赖的品牌！',
        zishen:'资深的行业经验',
        lingyu:'1998年开始第一代产品研发，20余年产品研发历史，中国较早从事发电机组和双电源切换控制系统研发和生产的企业之一；全球设有50多个办事处或代理机构，是全球行业市场最具影响力的设备制造商和方案提供商之一。',
        yingyong:'广泛的应用领域',
        yytext:'产品在国防、通信、电力、建筑、船舶、数据中心、消防、混合能源等领域中均得到广泛的应用。',
        rongyu:'优良的荣誉资质',
        tixi:'通过国内外多项体系认证，包括CCC、CE、CCS、BV、UL、ABS、RS认证和德国MTU实验室认证等，并参与起草GB/T37089-2018“往复式内燃机驱动的交流发电机组控制器”国家标准。',
        fengfu:'丰富的产品种类',
        chanpins:'产品种类包括发电机组控制器、低压配电控制器、船用发动机及功率管理控制器（PMS）、工程机械控制器、EMS、BMS、PCS、DVR、双电源开关、蓄电池充电器、发动机机体加热器、云监控、通信转换模块、电量保护模块、I/O扩展模块等近千种品类。',
        yanfa:'强大的研发团队',
        yongyou:'拥有占公司总人数30%的专业研发团队，每年在产品研发、智能制造和标准试验室方面持续投入大量资金，拥有行业内屈指可数的一流产品试验基地，累计获得600余项专利。',
        zhuanye:'专业的服务人员',
        zhuanyes:'公司集产品研发、生产、销售、服务为一体，拥有完善的销售服务和售后服务体系，每个区域都有专家型销售长期驻扎在当地，负责当地的售前与售后！',
        recentNews:'最新动态'
    },
    user:{
        account:'账户',
        verificationCode:'验证码',
        newPassword:'新密码',
        confirmPassword:'确认密码',
        retrievePassword:'找回密码',
        title:'密码设置成功，请重新登录！',
        futitle:'请记住新的账号密码',
        loginImmediately:'马上登录',
        backHomepage:'返回首页',
        mimamsg:'用户密码长度必须介于 5 和 20 之间',
        reenter:'请再次输入密码',
        pwdb:'两次输入密码不一致'
    },
    reg:{
        mailbox:'邮箱',
        password:'密码',
        confirmPassword:'确认密码',
        name:'昵称',
        mobilePhone:'手机',
        companyName:'公司名称',
        companyIndustry:'公司行业',
        state:'国家',
        address:'地址',
        register:'注册',
        registeredSuccessfully:'注册成功',
        rememberAccount:'请记住账号密码',
        namemsg:'用户昵称不能为空',
        passwordmsg:'用户密码不能为空',
        passwordmsg2:'用户密码长度必须介于 5 和 20 之间',
        phonemsg:'手机号不能为空',
        phonemsg2:'请输入正确的手机号码',
        addressmsg:'地址不能为空',
        emtail:'请输入正确的邮箱格式或手机格式',
        reenter:'请再次输入密码',
        inconformity:'两次输入密码不一致',
        iphoneeamil:'请输入手机号或邮箱'
    },
    search:{
        searchResult:'搜索结果',
        inquire:'查询',
        search:'搜索',
        dedao:'得到的结果',
        tiao:'条',
        all:'全部',
        socialRecruitment:'社会招聘',
        mobilePhone:'手机',
        mailbox:'邮箱',
        anlititle:'SmartGen案例'
    },
    project:{
        releaseTime:'发布时间',
        all:'全部应用案例'
    },
    scheme:{
        planToDownload:'方案下载',
        solutionOverview:'方案概述',
        shipin:'相关视频',
        relatedCases:'相关案例',
        relatedProducts:'相关产品'
    },
    product:{
        problemFeedback:'问题反馈',
        productOverview:'产品概述',
        technicalParameters:'技术参数',
        shipin:'相关视频',
        ziliao:'资料下载',
        relatedCases:'相关案例',
        relevantSolutions:'相关解决方案',
        versionNumber:'版本号',
        downloadLink:'下载链接',
        WorkingPowerRange:'工作电源范围',
        OverallDimension:'外形尺寸(mm)',
        InstallationDimension:'安装尺寸(mm)',
        WorkingTemperature:'工作温度',
        ProductReferenceFont:'产品参考字体',
        weight:'重量'
    },
    productinfo:{
        series:'系列',
        duibi:'对比',
        yiduibi:'已对比',
        fenlei:'分类',
        xuanzexinghao:'选择型号',
        qingxuanxinghao:'请选择型号'
    },
    ecu:{
        brand:'品牌',
        failureCause:'故障原因',
        reactionTitle:'故障反应',
        ansTitle:'纠正措施'
    },
    fankui:{
        productModel:'产品型号',
        productModelmsg:'产品型号不能为空',
        type:'类型',
        typeMsg:'产品类型不能为空',
        function:'功能相关',
        facade:'外观相关',
        software:'软件相关',
        hardware:'硬件相关',
        else:'其他',
        describe:'描述',
        describemsg:'问题描述不能为空',
        improvementSuggestions:'改善建议',
        improvementSuggestionsmsg:'改善建议不能为空',
        mailmsg:'邮箱不能为空',
        submit:'提交',
        operateSuccessfully:'操作成功'
    },
    wenti:{
        problemDescription:'问题描述',
        questionAnswering:'问题回答',
    },
    ziliao:{
        searchhint:'搜索在线产品以及下架产品',
        model:'型号',
        applicationTool:'应用工具下载',
        name:'名称',
        desc:'产品描述',
        dataDownload:'产品资料下载',
        ziliaoDownload:'资料下载',
        efiDrawings:'电喷图纸',
        offdataDownload:'下架产品资料',
        searchoffproduct:'搜索下架产品资料'
    },
    jiaru:{
        welfare:'众智福利',
        jobVacancy:'招聘职位',
        Department:'招聘部门',
        workingPlace:'工作地点',
        Zhengzhou:'郑州',
        releaseDate:'发布日期',
        recruitmentDetails:'招聘详情',
        salaryAndWelfare:'薪酬福利',
        salaryAndWelfareinfo:'行业具有竞争力的薪酬标准<br/>五险一金+人身意外伤害险<br/>免费营养午餐',
        paidVacation:'带薪假期',
        paidVacationinfo:'国家法定节假日<br/>婚假、产假、哺乳假、陪产假、丧假、病假、年休假等<br/>员工“育、病”时，给予必要关怀',
        holidayGift:'假日礼品',
        holidayGiftinfo:'端午、中秋、春节等节日福利<br/>每季度一次日常生活用品福利<br/>生日福利',
        culturalActivity:'文化活动',
        culturalActivityinfo:'公司级：周年庆、啤酒节、员工家庭日、年会等<br/>部门级：不定期聚餐、旅游、团建等<br/>协会：乐活协会、悦读协会、公益协会、商务礼仪协会',
        talentCultivation:'人才培养',
        talentCultivationinfo:'各类“走出去，请进来”的参观学习<br/>技术、营销、生产、管理等多通道职业发展路径<br/>优秀员工奖、创新改善奖、学历提升奖、职业技能提升奖',
        employeeCare:'员工关爱',
        employeeCareinfo:'职业健康体检<br/>全员体检<br/>女员工&40岁以上员工特殊关爱',
    },
    guanyu:{
        corporateMission:'企业使命',
        control:'让控制更智能!',
        spirit:'企业精神',
        spiritconetnt:'自强不息，众智成城。',
        vision:'企业愿景',
        visionconetnt:'缔造百年众智，做合作伙伴最值得信赖的品牌！',
        value:'企业价值观',
        valueconetnt:'创新、专注、精益求精；诚信、担当、合作共赢。',
        m:'月',
        statement:'环保声明',
        statementcontent:'我们通过技术创新，严格按照ISO14001等标准，实施全方位的过程控制与管理，成为真正的绿色企业',
        developmentHistory:'发展历程',
        contactUs:'联系我们',
        phone:'电话',
        theForeignTradeInAStraightLine:'外贸直线',
        fax:'传真',
        pbx:'总机',
        postcode:'邮编',
        mailbox:'邮箱',
        QQ:'QQ技术交流群',
        controlField:'发电机组控制领域',
        controlField2:'的行业引领者和标准制定者',
        controlFieldy:'20+年',
        agent:'国内办事处',
        agent2:'海外代理商销售模式',
        agenty:'>50个',
        patent:'持续创新，累计',
        patent2:'申请技术专利数百项',
        patenty:'>400项',
        user:'30%的员工致力于研发',
        user2:'探索行业前沿自动化方案',
        usery:'>300名',
        annualOutput:'拥有年产>50万台',
        annualOutput2:'各类控制模块的能力',
        annualOutputy:'>50万台',
        informatization:'办公区、生产区',
        informatization2:'总建筑面积>5万平',
        informatizationy:'>5万平方',
        researchAndDevelopment:'研发力量',
        researchAndDevelopment2:'超过30%员工的专业研发团队负责探索全新的智能化解决方案以提升全球行业设备的整体性能',
        smart:'智能制造',
        smart2:'⼯业4.0数字化制造⻋间，精益⽣产管理模式，充分满⾜各类批量定制化产品需求。',
        quality:'质量保证',
        quality2:'拥有⼀流标准试验室，产品品质稳居国际同⾏第⼀梯队，数百万台设备可靠运⾏在世界各地。',
        market:'专家销售',
        market2:'⼯程师级别销售团队，打造⼀站式⾼效精准服务，数千家⻓期优质客⼾遍布全球。',
        laochang:'金梭路厂区',
        laochangaddress:'中国·河南省郑州高新技术开发区金梭路28号',
        xinchang:'天健湖厂区',
        xinchangaddress:'郑州高新技术产业开发区雪梅街28号',
    },
    biaoshi:{
        title:'SmartGen标识诠释',
        content:'SmartGen是众智的英文注册商标。Smart是灵巧的、智能的、聪明的；Gen是generator的缩写，发电机组的意思。SmartGen的寓意也就是让发电机组变的更加智能、更加人性化、更好的为人类服务！',
        standardColors:'标准色',
        brandStandardColor:'品牌标准色',
        levels:'标准色阶',
        auxiliaryColor:'辅助色',
        fuzhu:'为保证标准色在延展应用中视觉的统一和协调，根据不同应用材质和实际制作需求，选用以上与标准色可协调搭配的颜色，作为标准色的辅助色',
        mascot:'IP形象诠释',
        jxyy:'以边牧犬为原型，寓意聪明、灵巧、智能，同时对应众智英文商标“SmartGen”，相得益彰',
        annotation:'寓意诠释',
        name:'取名Smart， 以边牧犬为造型基础，寓意聪明、智能',
        name2:'头部微扬，耳朵向上，精气神十足，象征企业干劲十足，蒸蒸日上',
        name3:'眼睛炯炯有神，眉毛短小精悍，嘴角上扬，充满自信和正能量',
        logo:'LOGO下载',
        Download:'下载文档'
    },
    lianxi:{
        placeholder:'请输入省级行政区所管辖的城市名称',
        companyName:'公司名称',
        theContact:'联系人',
        website:'网站',
        site:'地址'
    },
    seo:{
        title:'发电机组控制器,混合能源控制器,智能切换控制器,双电源控制器,工程机械控制器,船舶控制器,功率管理系统,充电器,云监控,加热器',
        keywords:'发电机组控制器,柴油发电机组控制器,混合能源控制器,智能切换控制器,双电源控制器,ATS控制器,工程机械控制器,船舶控制器,船用控制器,功率管理系统,充电器,蓄电池充电器,云监控,机组云监控,云监控系统,加热器,机组加热器,水套加热器',
        description:'众智科技主要从事发电机组控制器,柴油发电机组控制器,混合能源控制器,智能切换控制器,双电源控制器,ATS控制器,工程机械控制器,船舶控制器,船用控制器,功率管理系统,充电器,蓄电池充电器,云监控,机组云监控,云监控系统,加热器,机组加热器,水套加热器等产品的研发、生产、销售和服务',
        indextitle:'发电机组控制器,混合能源控制器,智能切换控制器,双电源控制器,工程机械控制器,船舶控制器,功率管理系统,充电器,云监控,加热器',
        indexkeywords:'柴油发电机机组控制器,发电机组控制器,并联控制器,发电机远程监控器,AMF,混合能源控制器,通讯基站控制器,灯塔控制器, 日出日落灯塔控制器,双电源控制器,智能切换控制器,ATS控制器, ,双电源切换控制器, ,旁路切换控制器,ATS,ATSE,船用控制器,船舶控制器,功率管理系统,PMS,综合电量保护模块,开关量扩展模块,消防水泵控制器,发动机CAN监控仪,柴驱空压机控制器,压裂车控制器,工业遥控器,双电源开关, 充电器,蓄电池充电器,铅酸电池充电器,加热器,机组加热器,水套加热器,强循环加热器,水加热器,机油加热器,云监控,云监控系统,云平台,控制屏,陆用发电机组控制屏,ATSE控制屏,船用发电机控制屏',
        indexdescription:'众智科技专业为发电机组厂商提供发电机控制器,柴油发电机组控制器, 并联控制器,发电机远程监控器,混合能源控制器,通讯基站控制器,灯塔控制器,双电源控制器,智能切换控制器,双电源切换控制器,ATSE控制器,三电源切换控制器,船用控制器,船舶控制器,功率管理系统,PMS,综合电量保护模块,开关量扩展模块, 消防水泵控制器,发动机CAN监控仪,柴驱空压机控制器,压裂车控制器,工业遥控器, 工程机械控制模块, 工业遥控器,双电源开关, 充电器,蓄电池充电器,铅酸电池充电器,加热器,机组加热器,水套加热器,强循环加热器,水加热器,机油加热器,云监控,云监控系统,云平台,控制屏,陆用发电机组控制屏,ATSE控制屏,船用发电机控制屏等产品',
        schemetitle:'机组控制解决方案，智能切换解决方案，船机控制解决方案，工程机械解决方案，云监控解决方案',
        schemekeywords:'多机并联解决方案，多机并网解决方案， AMF解决方案，自起动解决方案，CHP解决方案，船舶动力解决方案，应急电源解决方案，同步与负载均分解决方案，水泵机组解决方案，双电源切换解决方案，三电源切换解决方案，燃气机组解决方案，黑启动解决方案，高压机组解决方案，电信基站解决方案，电量保护解决方案，灯塔机组解决方案，云监控解决方案',
        scheme:'众智科技专业提供多机并联解决方案，多机并网解决方案， AMF解决方案，自起动解决方案，CHP解决方案，船舶动力解决方案，应急电源解决方案，同步与负载均分解决方案，水泵机组解决方案，双电源切换解决方案，三电源切换解决方案，燃气机组解决方案，黑启动解决方案，高压机组解决方案，电信基站解决方案，电量保护解决方案，灯塔机组解决方案，云监控解决方案等全新的智能化解决方案'
    },
    pinglun:{
        fabiaopinglun:'发表评论',
        shuodianshenme:'说点什么……',
        quanbupinglun:'全部评论',
        huifu:'回复',
        pinglun:'评论'
    },
    develop:{
        kehutitle:'我们与客户',
        kehu1:'众智科技秉承自强不息、众智成城的企业精神，坚持创新、专注、精益求精；诚信、担当、合作共赢的企业价值观，持续探索全新的智能化方案来提升全球行业设备的整体性能。',
        kehu2:'我们以客户为尊，以市场发展为导向，每年都会在产品研发、标准试验室、数字工厂方面投入大量资金，为客户提供超出预期的产品解决方案；',
        kehu3:'公司专家型销售团队始终以解决客户需求为己任，为客户提供专业、高效、完善的技术服务；超过30%的研发人员时刻待命，为满足客户多样化的产品需求持续努力；',
        gongyingshangtitle:'我们与供应商',
        gongyingshang1:'众智秉承 “诚信 担当 合作共赢”的经营理念同供应链合作伙伴共同成长，通过数字化技术的应用、紧密的交流合作，协同创新，不断提升企业和供应链的可持续发展能力，在满足政策监管、合规经营的基础上，承担社会责任，减少碳排放对人类环境的影响，众智科技期待通过和供应链伙伴的共同努力，打造绿色、透明、担当、可持续发展的供应链管理体制，使我们的产品和服务能够从源头符合环境、社会可持续发展的要求！为社会和人类作出自己的贡献！',
        yuangongtitle:'我们与员工',
        yuangong1:'员工是众智最宝贵的财富，众智与员工彼此信任，勇于担当，共同创建健康活力的企业氛围。众智努力为员工搭建成长与发展的舞台，充分发挥员工潜能，让每一位有理想的员工都能尽情施展才华，在实现“百年众智”的旅途中快乐工作，幸福生活！ ',
        shequtitle:'我们与社区',
        shequ1:'众智积极承担社会责任，融入当地社区，通过贡献教育、支持环保以及志愿、公益活动，践行着我们对社会可持续发展的承诺。',
        shequ2:'众智积极发挥我们在人才、技术、资金、文化及管理方面的优势，携手社会各界力量，助力创新人才培养为社会发展和文明进步注入源源不断的创新动力。同时，众智积极贡献社会公益事业，做真正负责任的企业公民。',
        huanjingtitle:'我们与环境',
        huanjing1:'众智致力于环境共生，以再生能源、绿色体系、绿色产品和全流程的绿色运营实践对环境承诺。秉承持续创新提高能效、降低排放、节约资源、再生能源利用的思想方针进行发展，在产品全生命周期严格进行环境管理，确保研发、设计、采购、生产、售后、物流和回收处理的过程符合环境可持续发展的要求，并不断研发绿色产品及技术，探索运用新技术应对新的环境问题与挑战。众智要实现环境共生发展的良性循环， 承担共建绿水青山的社会责任，铸就绿色生态圈。'
    }
}