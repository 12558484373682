<template>
  <div id="app">
    <!-- Google Tag Manager (noscript) -->
      <noscript v-if="this.$i18n.locale =='en'"><iframe src="https://www.googletagmanager.com/gtag/js?id=G-54RWL6B6SE"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->
    <keep-alive>
      <router-view :key="key" />
    </keep-alive>
  </div>
</template>
<script>
export default {
    name:'App',
    computed:{
      key(){
        return this.$route.name?this.$route.name+ +new Date():this.$route+ + new Date()
      }
    },
    mounted () {
      if(this.$i18n.locale =='zh'){
        var c=document.createElement('script');
        c.src='https://kefu5.cckefucloud.com/vclient/?webid=g89jd6ev135b0mx9d9wkqprw28g7y4kz&wc=a4eecbd9';
        var s=document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(c,s);
      }
    },
    created() {
      // if(this.$i18n.locale !='pt' || this.$i18n.locale !='es'){
      //   if (this._isMobile()) {
      //     window.location.replace('/mobile/?lang='+this.$i18n.locale)
      //   }
      // }
    },
    methods: {
      // 判断手机端还是PC端
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
    },
    watch: {
      '$route' () {
        if (window._czc) {
          let location = window.location
          let contentUrl = location.pathname + location.hash
          let refererUrl = '/'
          window._czc.push(['_trackPageview', contentUrl, refererUrl])
        }
      }
    }
}
</script>
<style>
  body{
    background: #f5f5f5;
  }
</style>
