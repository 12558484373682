module.exports = {
    iphone:'phone',
    lang:'EN',
    langc:'中文',
    lange:'English',
    langh:'繁体',
    name:'SMARTGEN(ZHENGZHOU) TECHNOLOGY CO., LTD.',
    jieshao:"Founded in 1998, SmartGen, a listed company on Shenzhen Stock Exchange GEM (stock code 301361), is mainly engaged in the research and development, production, sales and service of automatic control systems for internal combustion generator sets, low-voltage distribution automatic control systems, new energy automatic control system and other related automatic products. The company’s products are widely used in mining oil exploitation, data center, ship, intelligent building, bank, hospital, factory, telecom, infrastructure and other fields, with business covering all over the world. The company is a national specialized, fine, special and innovative “small giant” enterprise, a high-growth and high-tech enterprise, and a technology-intensive modern enterprise. Since its establishment, the company has invested heavily in product development, standard testing room, digital factory every year to stay ahead in the domestic and international markets. We have hundreds of patents and over 30% of the employees are dedicated to research and development. They are responsible for exploring new intelligent solutions to improve the overall performance of global industry equipment. We will continue to innovate and self-transcend as always, continuously improve our core competitiveness, enhance the value of enterprises and employees, create a complete industrial ecological chain of high quality, safe, green, low-carbon and sustainable development, and return our customers, shareholders, employees and society with high quality performance. ",
    manualInChinese:'Chinese Manual',
    englishManual:'English Manual',
    testingSoftware:'Software',
    typicalApplicationDiagram:'Typical Application',
    DModelDiagram:'3D Model Diagram',
    chineseSolution:'Chinese Solution',
    englishProgram:'English Solution',
    aSinglePageInChinese:'Chinese single page',
    chineseCommunicationProtocol:'Chinese Communication Protocol',
    englishCommunicationProtocol:'English Communication Protocol',
    shengming:'Statement: SmartGen adopts the marketing models of domestic direct sales and outside the mainland designated agents. All products are delivered directly from Zhengzhou factory in China.',
    inquire:'Query',
    notAvailable:'No search content',
    nosousuo:"Can't search what you want",
    BUG:'I want feedback',
    loginerror:'Please log in first, and then check',
    notLogIn:'Not logged in',
    logIn:'logon',
    close:'close',
    download:'Download',
    theFreeCall:'National toll free',
    dataType:'Data type',
    all:'All',
    getCode:'Get verification code',
    confirm:'Confirm',
    cancel:'Cancel',
    login:{
        zhineng:'Making control smarter!',
        dizao:'Building a century-old SmartGen, being the most trusted brand for partner',
        zhinengxin:'',
        fendou:'',
        welcome:'Login',
        userName:'Phone or E-mail',
        password:'Password',
        code:'Captcha',
        logIn:'Logon',
        register:'Register',
        codelogin:'Login with verification code',
        userlogin:'Login with account',
        forgetPassword:'Forget the password',
        rememberPassword:'Remember password',
        tishi:'Kindly reminder: It is common with the APP account of SmartGen products',
        usermsg : 'Username cannot be empty',
        pwdmsg:'Password cannot be empty',
        codemsg: 'Captcha cannot be empty',
        notnull:'Cannot be empty',
    },
    personal:{
        title:'My Center',
        change:'Change',
        iphone:'Phone number',
        notBound:'Not linked',
        toBind:'link',
        email:'Email address',
        weititle:'It is not linked, you can link to subscribe new products information',
        dyyj:'Subscribe email',
        qxdy:'Cancel subscription',
        changePassword:'Change password',
        loginPassword:'Change your login password',
        closeAnAccount:'Cancel the account',
        deldata:'Delete your account and related data permanently',
        logout:'Cancel',
        changeNickname:'Change nickname',
        replaceAPhoneNumber:'Change phone number',
        codeinput:'Please enter verification code',
        replaceTheEmail:'Change email',
        emailinput:'Please enter your email',
        oldpassword:'Please enter your original password',
        newpassword:'Please enter your new password',
        confirmpassword:'Confirm your new password again',
        modifySuccessfully:'Modify successfully',
        iphonemsg:'Please enter correct phone format',
        emailmsg:'Please enter correct email format'
    },
    layout: {
        search: 'Search',
        searchplaceholder :'Please input content',
        home:'Index',
        productcenter:'Products',
        productClassification:'PRODUCTS',
        Viewall:'Check all',
        solution:'Solutions',
        application:'Application Cases',
        technical:'Technical Support',
        download:'Download',
        model:'Product Sample',
        ECUfaultcode:'ECU Fault Codes',
        FAQ:'FAQS',
        Productfeedback:'User Feedback',
        about:'About Us',
        wenhua:'Culture',
        news:'News',
        honor:'Honors',
        logo:'Trade Mark',
        join:'Join Us',
        contact:'Contact Us',
        store:'Shop',
        cloudplatform:'Cloud Monitoring Platform',
        register:'Register',
        login:'Login',
        userName:'Username',
        exit:'Quit',
        followUs:'Please follow us',
        chineseWeChatServiceNumber:'Chinese WeChat Service Account',
        englishWeChatServiceNumber:'English WeChat Service Account',
        productAPP:'SmartGen Products APP',
        WeMedia:'Smartgen Media',
        sumaitong:'AliExpress Mall',
        taobao:'Taobao Mall',
        ali:'Alibaba Mall',
        a1688:'1688 Mall',
        address:'No. 28 Xuemei Street, Zhengzhou, Henan, China',
        LinkedIn:'LinkedIn platform of SmartGen',
        weibo:'YouTube',
        weibourl:'https://www.youtube.com/channel/UCxLKCBUbqLT5mT93j9mzbjQ?view_as=subscriber',
        boke:'Facebook',
        bokeurl:'https://www.facebook.com/smartgen98318',
        tencentVideo:'Linkedin',
        tencenturl:'https://www.linkedin.com/company/13719141/admin/',
        Youku:'Twitter',
        Youkuurl:'https://twitter.com/lcsmartgen',
        Tudou:'Pinterest',
        Tudouurl:'https://www.pinterest.com/smartgen980318/',
        DocIn:'VK',
        DocInurl:'https://vk.com/smartgen980318',
        baidu:'Blogger',
        baiduurl:'http://smartgenblog.blogspot.com/',
        develop:'Sustainable Development',
        xuanchuan:'Promotional Video',
        quanjing:'Exhibition hall'
    },
    index:{
        NewProducts:'New Products',
        xuanze:'Why SmartGen',
        dizao:'Building a century-old SmartGen, being the most trusted brand for partner',
        zishen:'Senior industry experience',
        lingyu:'First generation of product development in 1998, more than 20 years development history, one of the earliest companies in China engaged in the development and production of genset and dual power ATS.',
        yingyong:'Wide application fields',
        yytext:'The products can be applied to national defense, data center, infrastructure, ships, hybrid energy, micro-grid, engineering machinery, etc.',
        rongyu:'Excellent honor qualification',
        tixi:'The products have passed many domestic and foreign system certifications, including CCC, CE, CCS, BV,ABS, RS, UL, military certification, Germany MTU laboratory certification, etc. ',
        fengfu:'Various products',
        chanpins:'The product range includes generator set controllers, low-voltage distribution controllers, marine engine and power management systems (PMS), construction machinery controllers, EMS, BMS, PCS, DVR, dual power switches, battery chargers, engine block heaters, cloud monitoring, communication conversion modules, power protection modules, I/O expansion modules, and nearly a thousand other types.',
        yanfa:'Strong R&D team',
        yongyou:'The professional R&D team accounting for 30% of the total employees, continue to invest heavily in R&D, intelligent manufacturing and standard laboratory,  total obtain nearly 600 patents.',
        zhuanye:'Professional service personnel',
        zhuanyes:'The company has a perfect sales service and after-sales service system. Each region has an expert sales permanently stationed in the local area, responsible for the local pre-sales and after-sales.',
        recentNews:'News'
    },
    user:{
        account:'Account',
        verificationCode:'Captcha',
        newPassword:'The new password',
        confirmPassword:'Confirm Password',
        retrievePassword:'Get back Password',
        title:'The password is set successfully, please log in again!',
        futitle:'Please remember the new account password',
        loginImmediately:'Log in now',
        backHomepage:'Back to homepage',
        mimamsg:'The length of the user password must be between 5 and 20',
        reenter:'Please input the password again',
        pwdb:'Two input password are inconsistent'
    },
    reg:{
        mailbox:'E-mail',
        password:'password',
        confirmPassword:'Confirm password',
        name:'Name',
        mobilePhone:'Phone',
        companyName:'Company',
        companyIndustry:'Company Industry',
        state:'Country',
        address:'ADD',
        register:'register',
        registeredSuccessfully:'Registered successfully',
        rememberAccount:'Please remember the account password',
        namemsg:'The nickname of user cannot be empty',
        passwordmsg:'The password of user cannot be empty',
        passwordmsg2:'User password length must be between 5 and 20',
        phonemsg:'Mobile phone number cannot be empty',
        phonemsg2:'Please enter the correct mobile phone number',
        addressmsg:'Address cannot be empty',
        emtail:'Please enter the correct mailbox format',
        reenter:'Please enter password again',
        reenter:'Please input the password again',
        inconformity:'Two input password are inconsistent'
    },
    search:{
        searchResult:'Search result',
        inquire:'Inquire',
        search:'Search',
        dedao:'The results',
        tiao:'Strip',
        all:'whole',
        socialRecruitment:'Social recruitment',
        mobilePhone:'Phone',
        mailbox:'E-MAIL',
        anlititle:'SmartGen cases'
    },
    project:{
        releaseTime:'Release time',
        all:'All'
    },
    scheme:{
        planToDownload:'Solution Download',
        solutionOverview:'Program Overview',
        shipin:'Viedo',
        relatedCases:'Related Cases',
        relatedProducts:'Related Products'
    },
    product:{
        problemFeedback:'Problem Feedback',
        productOverview:'Product Overview',
        technicalParameters:'Technical Parameters',
        shipin:'Viedo',
        ziliao:'Data Download',
        relatedCases:'Related Cases',
        relevantSolutions:'Related Solutions',
        versionNumber:'Version',
        downloadLink:'Download Link',
        WorkingPowerRange:'Working PowerRange',
        OverallDimension:'Overall Dimension',
        InstallationDimension:'Installation Dimension',
        WorkingTemperature:'Working Temperature',
        ProductReferenceFont:'Product Reference Fon',
        ProductSinglePage:'Product Single Page',
        weight:'weight'
    },
    productinfo:{
        series:'Series',
        duibi:'Contrast',
        yiduibi:'Compared',
        fenlei:'Classify',
        xuanzexinghao:'Select Model',
        qingxuanxinghao:'Select Model'
    },
    ecu:{
        brand:'Brand',
        failureCause:'Fault Cause',
        reactionTitle:'Fault Response',
        ansTitle:'Corrective Action'
    },
    fankui:{
        productModel:'Product Model',
        productModelmsg:'Product model cannot be empty',
        type:'Type',
        typeMsg:'Product type cannot be empty',
        function:'FUNCTIONS',
        facade:'APPEARANCES',
        software:'SOFTWARES',
        hardware:'HARDWARES',
        else:'OTHERS',
        describe:'Description',
        describemsg:'Problem description cannot be empty',
        improvementSuggestions:'Suggestions',
        improvementSuggestionsmsg:'Improvement suggestion cannot be empty',
        mailmsg:'E-mail can not be empty',
        submit:'Submit',
        operateSuccessfully:'Operation succeeded'
    },
    wenti:{
        problemDescription:'Question Description',
        questionAnswering:'Question Solving',
    },
    ziliao:{
        searchhint:'Search for online and offline products',
        model:'Type',
        applicationTool:'Application Tool',
        name:'Name',
        desc:'Product Description',
        dataDownload:'Download',
        ziliaoDownload:'Data Download',
        efiDrawings:'Drawing Download',
        offdataDownload:'Offline Download',
        searchoffproduct:'Search offline products'
    },
    jiaru:{
        welfare:'SmartGen welfare',
        jobVacancy:'Job Vacancy',
        Department:'Recruitment department',
        workingPlace:'Place of work',
        Zhengzhou:'Zhengzhou',
        releaseDate:'Release date',
        recruitmentDetails:'Recruitment details',
        salaryAndWelfare:'Compensation and benefits',
        salaryAndWelfareinfo:'Five social insurance and one housing fund and supplementary commercial insurance<br/>Five social insurance and one housing fund and supplementary commercial insurance<br/>Free staff restaurant',
        paidVacation:'Paid holiday',
        paidVacationinfo:'National statutory holidays<br/>Marital leave, maternity leave, breastfeeding leave, paternity leave, bereavement leave, sick leave and annual leave<br/>Give necessary care to employees in case of "childbirth or illness"',
        holidayGift:'Holiday Gifts',
        holidayGiftinfo:'Festival benefits such as Dragon Boat Festival, Mid-Autumn Festival and Spring Festival<br/>Every quarterly daily necessities benefits<br/>Birthday benefits',
        culturalActivity:'Cultural activities',
        culturalActivityinfo:'Company-level activities: anniversary, beer festival, employee family day, annual meeting, etc.<br/>Department-level activities: irregular dinners, tourism, group building, etc.<br/>Associations: Loho Association, Reading Association, Public Welfare Association, Business Etiquette Association',
        talentCultivation:'Personnel Training',
        talentCultivationinfo:'Go out and invite in learning opportunities<br/>Technology, marketing, production, management and other multi-channel career development paths<br/>Excellent Staff Award, Innovation and Improvement Award, Education Promotion Award and Vocational Skills Promotion Award',
        employeeCare:'Employee Care',
        employeeCareinfo:'Occupational health examination<br/>Physical examination of the whole staff<br/>Female employees & special care for employees over 40 years old',
    },
    guanyu:{
        corporateMission:'Enterprise Mission:',
        control:'Making Control Smarter!',
        spirit:'Enterprise Vision:',
        spiritconetnt:'Building a century-old SmartGen,being the most trusted brand for partner!',
        vision:'Enterprise Values:',
        visionconetnt:'Innovation, Focus, Lean-Better;Integrity, Responsibility, Win-Win.',
        value:'Enterprise Spirit:',
        valueconetnt:'Continuous Improvement, Teamwork Creates Value.',
        m:'',
        statement:'Environmental Statement',
        statementcontent:'By means of technological innovation, strictly follow ISO14001 and other standards, implement all-round control and management, we determine to be a true green enterprise.',
        developmentHistory:'Development history',
        contactUs:'Contact us',
        phone:'TEL',
        theForeignTradeInAStraightLine:'Foreign Trade Straight Line',
        fax:'FAX',
        pbx:'PBX',
        postcode:'Postcode',
        mailbox:'E-mail',
        QQ:'QQ Technical Communication group',
        controlField:'',
        controlField2:'Leader & Standards setter in genset control',
        controlFieldy:'23 year',
        agent:'',
        agent2:'Offices & agents in China and overseas',
        agenty:'More than 50',
        patent:'',
        patent2:'Patents',
        patenty:'398 items',
        user:'',
        user2:'30% of employees contribute to research and develop',
        usery:'More than 300 people',
        annualOutput:'',
        annualOutput2:'An annual production of more than 500,000 units',
        annualOutputy:'500,000 units',
        informatization:'',
        informatization2:'Overall floorage>50,000 square meters',
        informatizationy:'Industry 4.0',
        researchAndDevelopment:'R&D Strength',
        researchAndDevelopment2:'Professional R&D team occupies 30% of employees contribute to explore new intelligent solutions',
        smart:'Intelligent Manufacturing',
        smart2:'Seamless integration and data interaction of PLM, MES, WMS and other system information with REP as the data center',
        quality:'Quality Guarantee',
        quality2:'One of the leading product test bases in the industry, product has successively certified by CCC, CE, UL, CCS, BV, etc. ',
        market:'Sales Team',
        market2:'SmartGen salesman are all expert who have been working in the industry for many years and can independently provide professional and efficient pre-sales, sales and after-sales service',
        laochang:'Jin Suo Lu factory',
        laochangaddress:'No. 28, Jinsuo Road, Zhengzhou high tech Development Zone, Henan Province, China',
        xinchang:'Tian Jian Hu factory',
        xinchangaddress:'No.28 Xuemei Road, Zhengzhou high tech Development Zone, Henhan Province, China',
    },
    biaoshi:{
        title:'The meaning of SmartGen',
        content:'SmartGen is our English trademark."Smart" means ingenious, intelligent and clever,"Gen" is the abbreviation of gen-set.SmartGen implies making gen-set more intelligent and humanized to serve humans better!',
        standardColors:'STANDARD COLORS',
        brandStandardColor:'Brand standard color',
        levels:'The standard color order',
        auxiliaryColor:'Auxiliary color',
        fuzhu:'In order to ensure the standard color visual unity and coordination in the extended application, according to the different application materials and actual production needs, choose more coordinated with standard color can match',
        mascot:'Mascot',
        jxyy:'Based on the characteristics of border collie, as it is clever, skillful, and intelligent, the name Smart represents these features and corresponds to the English trademark “SmartGen”.',
        annotation:'Meaning',
        name:'Name it Smart as the prototype comes from border collie, implying smart and intelligent spirits.',
        name2:'With heading slightly upward, ears going straight up, Smart is full of vigor, which implies SmartGen is full of energy and heading toward a prosperous future.',
        name3:'Smart’s eyes are bright, eyebrows are short and lean, and mouth is rising a smile. Smart is full of confidence and is imbued with positive energy.',
        logo:'SmartGen Logos Download',
        Download:'Download the document'
    },
    lianxi:{
        placeholder:'',
        companyName:'company name',
        theContact:'Contact',
        website:'Website',
        site:'Address'
    },
    seo:{
        title:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        keywords:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        description:'SmartGen is a technological modernization enterprise, focusing on intelligent control system for engine, generator and dual power supply, as well as related products, and concentrating on design, research and development, manufacture, sale and services.Products are widely used in military industry, mining and oil exploration, data centers, ships, intelligent buildings, banks, hospitals, telecommunications, infrastructure and other fields.',
        indextitle:'SmartGen-Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        indexkeywords:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module, Intelligent Generator Controller,ASM controller,AMF controller,Synchronization controller,Parallel controller,Load share Controller,Lighting tower controller,ATSE,ATS,Power Management System,Pump controller,Fire pump controller,Irrigation controller,Air pump controller,Jacket Heater,Engine Water Heater,Cloud Monitoring Module',
        indexdescription:'SmartGen is a technological modernization enterprise, focusing on intelligent control system for engine, generator and dual power supply, as well as related products, and concentrating on design, research and development, manufacture, sale and services.Products are widely used in military industry, mining and oil exploration, data centers, ships, intelligent buildings, banks, hospitals, telecommunications, infrastructure and other fields.',
        schemetitle:'Solutions - Genset Control Solutions,ATSE Solutions,Marine Control Solutions,Engine Control Solutions ',
        schemekeywords:'Parallel Solution,Lighting Tower Set Solution,Solution for Telecom Base Station,High Voltage Unit Solution,Black Start Solution,ATS Switching Solution,Synchronization and Load Share,Ship Power SolutionPump Unit Solution',
        scheme:'SmartGen striving for Genset Control Solutions,ATSE Solutions,Marine Control Solutions,Engine Control Solutions  to improve the overall performance of global industry equipment.'
    },
    pinglun:{
        fabiaopinglun:'Comments',
        shuodianshenme:'Say something……',
        quanbupinglun:'All comments',
        huifu:'reply',
        pinglun:'comment'
    },
    develop:{
        kehutitle:'We and Customer:',
        kehu1:'SmartGen adheres to the enterprise spirit of “Continuous improvement, teamwork creates value”, and insists on the value of “Innovation, Focus, Lean-better; Integrity, Responsibility, Win-win”, continues to explore new intelligent solutions to improve the overall performance of global industry equipment.',
        kehu2:'We prioritize customer and are market-oriented, invest a lot of money in product research and development, standard laboratory and digital factory every year to provide customers with product solutions beyond expectations;',
        kehu3:"The company's expert sales team always takes solving customer needs as its responsibility and provides customers with professional, efficient and perfect technical services; More than 30% of R&D personnel are on standby at all times, continuously striving to meet the diversified product needs of customers;",
        gongyingshangtitle:'We and Supplier:',
        gongyingshang1:'SmartGen adheres to the business philosophy of “Integrity, Responsibility, Win-Win” and grows together with supply chain partners. Through the application of digital technology, close exchanges and cooperation, collaborative innovation, it continuously improves the sustainable development ability of enterprise and supply chains. On the basis of meeting policy supervision and compliance management, it undertakes social responsibility and reduces the impact of carbon emissions on the human environment. SmartGen expects to build a green, transparent, responsible and sustainable supply chain management system through joint efforts with supply chain partners, so that our products and services can meet the requirements of environmental and social sustainable development from the source and make its own contribution to society and mankind!',
        yuangongtitle:'We and Employees：',
        yuangong1:'Employees are the most precious wealth of SmartGen. SmartGen and employees trust each other and have the courage to take responsibility to jointly create a healthy and dynamic enterprise atmosphere. SmartGen strives to build a stage for employees to grow and develop, give full play to their potential, and let every employee with ideals display their talents to the fullest, and work happily and live happily in the journey of realizing “Century-old SmartGen”!',
        shequtitle:'We and Community:',
        shequ1:'SmartGen actively undertakes social responsibility, integrates into local communities, and fulfills its commitment to sustainable social development by contributing to education, supporting environmental protection and voluntary and public welfare activities.',
        shequ2:'SmartGen actively exerts our advantages in talents, technology, capital, culture and management, and joins hands with all sectors of society to help cultivate innovative talents and inject an endless stream of innovative power into social development and civilization progress. At the same time, SmartGen actively contributes to social welfare undertakings and becomes a truly responsible corporate citizen.',
        huanjingtitle:'We and Environment:',
        huanjing1:'SmartGen is committed to environmental symbiosis, practicing its commitment to the environment with renewable energy, green system, green products and green operation practice of the whole process. Adhering to the principles of continuous innovation to improve energy efficiency, reduce emissions, save resources and utilize renewable energy, we will strictly carry out environmental management in the whole life cycle of products, ensure that the processes of research and development, design, purchasing, production, after-sales, logistics and recycling meet the requirements of environmental sustainable development, continuously research and develop green products and technologies, and explore the use of new technologies to deal with new environmental problems and challenges. SmartGen should realize the virtuous circle of environmental symbiotic development, undertake the social responsibility of building lucid waters and lush mountains, and create a green ecosystem.'
    }
}