module.exports = {
    iphone:'手機端',
    lang:'CN',
    langc:'中文',
    lange:'English',
    langh:'繁體',
    name:'鄭州眾智科技股份有限公司',
    jieshao:'眾智科技始建於1998年，深交所創業板（股票代碼301361）上市企業，主要從事發動機自動控製系統、發電機和發電機組自動控製系統、低壓配電自動控製系統、新能源自動控製系統等相關自動化產品的研發、生產、銷售和服務。公司產品廣泛應用於礦山石油開采、數據中心、船舶、智能大廈、銀行、醫院、工廠、電信、基建等領域，業務範圍遍布全球。公司是國家專精特新「小巨人」企業，是高成長性、高新技術企業，也是技術密集型現代化企業。自成立以來，每年都會在產品研發、標準試驗室、數字工廠方面投入大量資金，以保持公司在國內國際市場中的領先地位。我們擁有數百項專利，有超過30%的員工致力於研發工作，負責探索全新的智能化方案來提升全球行業設備的整體性能。我們將一如既往地不斷創新與自我超越，持續提高核心競爭力，提升企業和員工價值，打造優質、安全、綠色、低碳等可持續發展的完整產業生態鏈，以優質的業績回報客戶、回報股東、回報員工、回報社會！',
    manualInChinese:'中文說明書',
    englishManual:'英文說明書',
    testingSoftware:'測試軟件',
    typicalApplicationDiagram:'典型應用圖',
    DModelDiagram:'三維模型圖',
    chineseSolution:'中文方案',
    englishProgram:'英文方案',
    aSinglePageInChinese:'中文單頁',
    ProductSinglePage:'產品單頁',
    chineseCommunicationProtocol:'中文通訊協議',
    englishCommunicationProtocol:'英文通訊協議',
    shengming:'聲明：眾智科技采用國內直銷、大陸以外指定代理商的模式銷售產品，所有產品均由中國·鄭州工廠直接發貨',
    inquire:'查詢',
    notAvailable:'暫無搜索內容',
    nosousuo:'搜索不到想要的內容',
    BUG:'我要反饋',
    loginerror:'請先登陸後再查詢',
    notLogIn:'未登錄',
    logIn:'登錄',
    close:'關閉',
    download:'下載',
    theFreeCall:'全國免費電話',
    dataType:'資料類型',
    all:'全部',
    getCode:'獲取驗證碼',
    confirm:'確定',
    cancel:'取消',
    login:{
        zhineng:'讓控製更智能！',
        dizao:'締造百年眾智，做合作夥伴最值得信賴的品牌',
        zhinengxin:'為世界上每一臺設備都打造一顆更加智能的心',
        fendou:'為滿足合作夥伴對產品多樣化和高品質的追求而持續奮鬥',
        welcome:'歡迎登錄',
        userName:'手機號/郵箱',
        password:'密碼',
        code:'驗證碼',
        logIn:'登錄',
        register:'註冊',
        codelogin:'驗證碼登錄',
        userlogin:'賬號登錄',
        forgetPassword:'忘記密碼',
        rememberPassword:'記住密碼',
        tishi:'溫馨提示：與智雲平臺賬號通用',
        usermsg : '用戶名不能為空',
        pwdmsg:'密碼不能為空',
        codemsg: '驗證碼不能為空',
        notnull:'不能為空',
    },
    personal:{
        title:'個人中心',
        change:'更改',
        iphone:'手機號',
        notBound:'未綁定',
        toBind:'去綁定',
        email:'郵箱地址',
        weititle:'未綁定，綁定後可訂閱新品推薦',
        dyyj:'訂閱郵件',
        qxdy:'取消訂閱',
        changePassword:'修改密碼',
        loginPassword:'修改您的登錄密碼',
        closeAnAccount:'註銷賬號',
        deldata:'永久刪除您的賬號及相關數據',
        logout:'註銷',
        changeNickname:'修改昵稱',
        replaceAPhoneNumber:'更換手機號',
        codeinput:'請輸入驗證碼',
        replaceTheEmail:'更換郵箱',
        emailinput:'請輸入您的郵箱',
        oldpassword:'請輸入您的原始密碼',
        newpassword:'請輸入新密碼',
        confirmpassword:'再次確認新密碼',
        modifySuccessfully:'修改成功',
        iphonemsg:'請輸入正確的手機格式',
        emailmsg:'請輸入正確的郵箱格式'
    },
    layout: {
        search: '搜索',
        searchplaceholder :'請輸入內容',
        home:'首頁',
        productcenter:'產品中心',
        productClassification:'產品分類',
        Viewall:'查看全部',
        solution:'解決方案',
        application:'應用案例',
        technical:'技術支持',
        download:'資料下載',
        model:'選型樣本',
        ECUfaultcode:'ECU故障代碼',
        FAQ:'常見問題解答',
        Productfeedback:'用戶反饋',
        about:'關於眾智',
        wenhua:'眾智文化',
        news:'企業新聞',
        honor:'企業榮譽',
        logo:'企業標識',
        join:'加入眾智',
        contact:'聯系眾智',
        store:'商城',
        cloudplatform:'智雲平臺',
        register:'註冊',
        login:'登錄',
        userName:'用戶名',
        exit:'退出',
        followUs:'關註我們',
        chineseWeChatServiceNumber:'眾智中文微信訂閱號',
        englishWeChatServiceNumber:'眾智英文微信服務號',
        productAPP:'眾智產品APP',
        WeMedia:'眾智自媒體',
        sumaitong:'速賣通商城',
        taobao:'淘寶商城',
        ali:'阿裏國際站',
        a1688:'1688',
        address:'中國·河南省鄭州市高新區雪梅街28號',
        weibo:'眾智微博平臺',
        weibourl:'https://weibo.com/smartgenwb',
        boke:'眾智博客平臺',
        bokeurl:'http://blog.sina.com.cn/u/2490663307',
        tencentVideo:'',
        tencenturl:'https://v.qq.com/s/videoplus/307236628',
        Youku:'',
        Youkuurl:'https://i.youku.com/smartgen',
        Tudou:'',
        Tudouurl:'http://id.tudou.com/smartgen',
        DocIn:'眾智豆丁文庫',
        DocInurl:'http://www.docin.com/smartgen',
        baidu:'眾智百度文庫',
        baiduurl:'https://cuttlefish.baidu.com/shop/a20ffe4733687e21af45a945',
        develop:'可持續發展',
        xuanchuan:'宣傳片',
        quanjing:'全景展廳'
    },
    index:{
        NewProducts:'新品推薦',
        xuanze:'為什麽選擇眾智',
        dizao:'締造百年眾智，做合作夥伴最值得信賴的品牌！',
        zishen:'資深的行業經驗',
        lingyu:'1998年開始第一代產品研發，20余年產品研發歷史，中國較早從事發電機組和雙電源切換控製系統研發和生產的企業之一；全球設有50多個辦事處或代理機構，是全球行業市場最具影響力的設備製造商和方案提供商之一。',
        yingyong:'廣泛的應用領域',
        yytext:'產品在國防、通信、電力、建築、船舶、數據中心、消防、混合能源等領域中均得到廣泛的應用。',
        rongyu:'優良的榮譽資質',
        tixi:'通過國內外多項體系認證，包括CCC、CE、CCS、BV、UL、ABS、RS認證和德國MTU實驗室認證等，並參與起草GB/T37089-2018「往復式內燃機驅動的交流發電機組控製器」國家標準。',
        fengfu:'豐富的產品種類',
        chanpins:'產品種類包括發電機組控制器、低壓配電控制器、船用發動機及功率管理控制器（PMS）、工程機械控制器、EMS、BMS、PCS、DVR、雙電源開關、蓄電池充電器、發動機機體加熱器、雲監控、通信轉換模塊、電量保護模塊、I/O擴展模塊等近千種品類。',
        yanfa:'強大的研發團隊',
        yongyou:'擁有占公司總人數30%的專業研發團隊，每年在產品研發、智能製造和標準試驗室方面持續投入大量資金，擁有行業內屈指可數的一流產品試驗基地，累計獲得600余項專利。',
        zhuanye:'專業的服務人員',
        zhuanyes:'公司集產品研發、生產、銷售、服務為一體，擁有完善的銷售服務和售後服務體系，每個區域都有專家型銷售長期駐紮在當地，負責當地的售前與售後！',
        recentNews:'最新動態'
    },
    user:{
        account:'賬戶',
        verificationCode:'驗證碼',
        newPassword:'新密碼',
        confirmPassword:'確認密碼',
        retrievePassword:'找回密碼',
        title:'密碼設置成功，請重新登錄！',
        futitle:'請記住新的賬號密碼',
        loginImmediately:'馬上登錄',
        backHomepage:'返回首頁',
        mimamsg:'用戶密碼長度必須介於 5 和 20 之間',
        reenter:'請再次輸入密碼',
        pwdb:'兩次輸入密碼不一致'
    },
    reg:{
        mailbox:'郵箱',
        password:'密碼',
        confirmPassword:'確認密碼',
        name:'昵稱',
        mobilePhone:'手機',
        companyName:'公司名稱',
        companyIndustry:'公司行業',
        state:'國家',
        address:'地址',
        register:'註冊',
        registeredSuccessfully:'註冊成功',
        rememberAccount:'請記住賬號密碼',
        namemsg:'用戶昵稱不能為空',
        passwordmsg:'用戶密碼不能為空',
        passwordmsg2:'用戶密碼長度必須介於 5 和 20 之間',
        phonemsg:'手機號不能為空',
        phonemsg2:'請輸入正確的手機號碼',
        addressmsg:'地址不能為空',
        emtail:'請輸入正確的郵箱格式或手機格式',
        reenter:'請再次輸入密碼',
        inconformity:'兩次輸入密碼不一致',
        iphoneeamil:'請輸入手機號或郵箱'
    },
    search:{
        searchResult:'搜索結果',
        inquire:'查詢',
        search:'搜索',
        dedao:'得到的結果',
        tiao:'條',
        all:'全部',
        socialRecruitment:'社會招聘',
        mobilePhone:'手機',
        mailbox:'郵箱',
        anlititle:'SmartGen案例'
    },
    project:{
        releaseTime:'發布時間',
        all:'全部應用案例'
    },
    scheme:{
        planToDownload:'方案下載',
        solutionOverview:'方案概述',
        shipin:'相關視頻',
        relatedCases:'相關案例',
        relatedProducts:'相關產品'
    },
    product:{
        problemFeedback:'問題反饋',
        productOverview:'產品概述',
        technicalParameters:'技術參數',
        shipin:'相關視頻',
        ziliao:'資料下載',
        relatedCases:'相關案例',
        relevantSolutions:'相關解決方案',
        versionNumber:'版本號',
        downloadLink:'下載鏈接',
        WorkingPowerRange:'工作電源範圍',
        OverallDimension:'外形尺寸(mm)',
        InstallationDimension:'安裝尺寸(mm)',
        WorkingTemperature:'工作溫度',
        ProductReferenceFont:'產品參考字體',
        weight:'重量'
    },
    productinfo:{
        series:'系列',
        duibi:'對比',
        yiduibi:'已對比',
        fenlei:'分類',
        xuanzexinghao:'選擇型號',
        qingxuanxinghao:'請選擇型號'
    },
    ecu:{
        brand:'品牌',
        failureCause:'故障原因',
        reactionTitle:'故障反應',
        ansTitle:'糾正措施'
    },
    fankui:{
        productModel:'產品型號',
        productModelmsg:'產品型號不能為空',
        type:'類型',
        typeMsg:'產品類型不能為空',
        function:'功能相關',
        facade:'外觀相關',
        software:'軟件相關',
        hardware:'硬件相關',
        else:'其他',
        describe:'描述',
        describemsg:'問題描述不能為空',
        improvementSuggestions:'改善建議',
        improvementSuggestionsmsg:'改善建議不能為空',
        mailmsg:'郵箱不能為空',
        submit:'提交',
        operateSuccessfully:'操作成功'
    },
    wenti:{
        problemDescription:'問題描述',
        questionAnswering:'問題回答',
    },
    ziliao:{
        searchhint:'搜索在線產品以及下架產品',
        model:'型號',
        applicationTool:'應用工具下載',
        name:'名稱',
        desc:'產品描述',
        dataDownload:'產品資料下載',
        ziliaoDownload:'資料下載',
        efiDrawings:'電噴圖紙',
        offdataDownload:'下架產品資料',
        searchoffproduct:'搜索下架產品資料'
    },
    jiaru:{
        welfare:'眾智福利',
        jobVacancy:'招聘職位',
        Department:'招聘部門',
        workingPlace:'工作地點',
        Zhengzhou:'鄭州',
        releaseDate:'發布日期',
        recruitmentDetails:'招聘詳情',
        salaryAndWelfare:'薪酬福利',
        salaryAndWelfareinfo:'行業具有競爭力的薪酬標準<br/>五險一金+人身意外傷害險<br/>免費營養午餐',
        paidVacation:'帶薪假期',
        paidVacationinfo:'國家法定節假日<br/>婚假、產假、哺乳假、陪產假、喪假、病假、年休假等<br/>員工「育、病」時，給予必要關懷',
        holidayGift:'假日禮品',
        holidayGiftinfo:'端午、中秋、春節等節日福利<br/>每季度一次日常生活用品福利<br/>生日福利',
        culturalActivity:'文化活動',
        culturalActivityinfo:'公司級：周年慶、啤酒節、員工家庭日、年會等<br/>部門級：不定期聚餐、旅遊、團建等<br/>協會：樂活協會、悅讀協會、公益協會、商務禮儀協會',
        talentCultivation:'人才培養',
        talentCultivationinfo:'各類「走出去，請進來」的參觀學習<br/>技術、營銷、生產、管理等多通道職業發展路徑<br/>優秀員工獎、創新改善獎、學歷提升獎、職業技能提升獎',
        employeeCare:'員工關愛',
        employeeCareinfo:'職業健康體檢<br/>全員體檢<br/>女員工&40歲以上員工特殊關愛',
    },
    guanyu:{
        corporateMission:'企業使命',
        control:'讓控製更智能!',
        spirit:'企業精神',
        spiritconetnt:'自強不息，眾智成城。',
        vision:'企業願景',
        visionconetnt:'締造百年眾智，做合作夥伴最值得信賴的品牌！',
        value:'企業價值觀',
        valueconetnt:'創新、專註、精益求精；誠信、擔當、合作共贏。',
        m:'月',
        statement:'環保聲明',
        statementcontent:'我們通過技術創新，嚴格按照ISO14001等標準，實施全方位的過程控製與管理，成為真正的綠色企業',
        developmentHistory:'發展歷程',
        contactUs:'聯系我們',
        phone:'電話',
        theForeignTradeInAStraightLine:'外貿直線',
        fax:'傳真',
        pbx:'總機',
        postcode:'郵編',
        mailbox:'郵箱',
        QQ:'QQ技術交流群',
        controlField:'發電機組控製領域',
        controlField2:'的行業引領者和標準製定者',
        controlFieldy:'20+年',
        agent:'國內辦事處',
        agent2:'海外代理商銷售模式',
        agenty:'>50個',
        patent:'持續創新，累計',
        patent2:'申請技術專利數百項',
        patenty:'>400項',
        user:'30%的員工致力於研發',
        user2:'探索行業前沿自動化方案',
        usery:'>300名',
        annualOutput:'擁有年產>50萬臺',
        annualOutput2:'各類控製模塊的能力',
        annualOutputy:'>50萬臺',
        informatization:'辦公區、生產區',
        informatization2:'總建築面積>5萬平',
        informatizationy:'>5萬平方',
        researchAndDevelopment:'研發力量',
        researchAndDevelopment2:'超過30%員工的專業研發團隊負責探索全新的智能化解決方案以提升全球行業設備的整體性能',
        smart:'智能製造',
        smart2:'⼯業4.0數字化製造⻋間，精益⽣產管理模式，充分滿⾜各類批量定製化產品需求。',
        quality:'質量保證',
        quality2:'擁有⼀流標準試驗室，產品品質穩居國際同⾏第⼀梯隊，數百萬臺設備可靠運⾏在世界各地。',
        market:'專家銷售',
        market2:'⼯程師級別銷售團隊，打造⼀站式⾼效精準服務，數千家⻓期優質客⼾遍布全球。',
        laochang:'金梭路廠區',
        laochangaddress:'中國·河南省鄭州高新技術開發區金梭路28號',
        xinchang:'天健湖廠區',
        xinchangaddress:'鄭州高新技術產業開發區雪梅街28號',
    },
    biaoshi:{
        title:'SmartGen標識詮釋',
        content:'SmartGen是眾智的英文註冊商標。Smart是靈巧的、智能的、聰明的；Gen是generator的縮寫，發電機組的意思。SmartGen的寓意也就是讓發電機組變的更加智能、更加人性化、更好的為人類服務！',
        standardColors:'標準色',
        brandStandardColor:'品牌標準色',
        levels:'標準色階',
        auxiliaryColor:'輔助色',
        fuzhu:'為保證標準色在延展應用中視覺的統一和協調，根據不同應用材質和實際製作需求，選用以上與標準色可協調搭配的顏色，作為標準色的輔助色',
        mascot:'IP形象诠释',
        jxyy:'以邊牧犬為原型，寓意聰明、靈巧、智能，同時對應眾智英文商標「SmartGen」，相得益彰',
        annotation:'寓意詮釋',
        name:'取名Smart， 以邊牧犬為造型基礎，寓意聰明、智能',
        name2:'頭部微揚，耳朵向上，精氣神十足，象征企業幹勁十足，蒸蒸日上',
        name3:'眼睛炯炯有神，眉毛短小精悍，嘴角上揚，充滿自信和正能量',
        logo:'LOGO下載',
        Download:'下載文檔'
    },
    lianxi:{
        placeholder:'請輸入省級行政區所管轄的城市名稱',
        companyName:'公司名稱',
        theContact:'聯系人',
        website:'網站',
        site:'地址'
    },
    seo:{
        title:'發電機組控製器,混合能源控製器,智能切換控製器,雙電源控製器,工程機械控製器,船舶控製器,功率管理系統,充電器,雲監控,加熱器',
        keywords:'發電機組控製器,柴油發電機組控製器,混合能源控製器,智能切換控製器,雙電源控製器,ATS控製器,工程機械控製器,船舶控製器,船用控製器,功率管理系統,充電器,蓄電池充電器,雲監控,機組雲監控,雲監控系統,加熱器,機組加熱器,水套加熱器',
        description:'眾智科技主要從事發電機組控製器,柴油發電機組控製器,混合能源控製器,智能切換控製器,雙電源控製器,ATS控製器,工程機械控製器,船舶控製器,船用控製器,功率管理系統,充電器,蓄電池充電器,雲監控,機組雲監控,雲監控系統,加熱器,機組加熱器,水套加熱器等產品的研發、生產、銷售和服務',
        indextitle:'發電機組控製器,混合能源控製器,智能切換控製器,雙電源控製器,工程機械控製器,船舶控製器,功率管理系統,充電器,雲監控,加熱器',
        indexkeywords:'柴油發電機機組控製器,發電機組控製器,並聯控製器,發電機遠程監控器,AMF,混合能源控製器,通訊基站控製器,燈塔控製器, 日出日落燈塔控製器,雙電源控製器,智能切換控製器,ATS控製器, ,雙電源切換控製器, ,旁路切換控製器,ATS,ATSE,船用控製器,船舶控製器,功率管理系統,PMS,綜合電量保護模塊,開關量擴展模塊,消防水泵控製器,發動機CAN監控儀,柴驅空壓機控製器,壓裂車控製器,工業遙控器,雙電源開關, 充電器,蓄電池充電器,鉛酸電池充電器,加熱器,機組加熱器,水套加熱器,強循環加熱器,水加熱器,機油加熱器,雲監控,雲監控系統,雲平臺,控製屏,陸用發電機組控製屏,ATSE控製屏,船用發電機控製屏',
        indexdescription:'眾智科技專業為發電機組廠商提供發電機控製器,柴油發電機組控製器, 並聯控製器,發電機遠程監控器,混合能源控製器,通訊基站控製器,燈塔控製器,雙電源控製器,智能切換控製器,雙電源切換控製器,ATSE控製器,三電源切換控製器,船用控製器,船舶控製器,功率管理系統,PMS,綜合電量保護模塊,開關量擴展模塊, 消防水泵控製器,發動機CAN監控儀,柴驅空壓機控製器,壓裂車控製器,工業遙控器, 工程機械控製模塊, 工業遙控器,雙電源開關, 充電器,蓄電池充電器,鉛酸電池充電器,加熱器,機組加熱器,水套加熱器,強循環加熱器,水加熱器,機油加熱器,雲監控,雲監控系統,雲平臺,控製屏,陸用發電機組控製屏,ATSE控製屏,船用發電機控製屏等產品',
        schemetitle:'機組控製解決方案，智能切換解決方案，船機控製解決方案，工程機械解決方案，雲監控解決方案',
        schemekeywords:'多機並聯解決方案，多機並網解決方案， AMF解決方案，自起動解決方案，CHP解決方案，船舶動力解決方案，應急電源解決方案，同步與負載均分解決方案，水泵機組解決方案，雙電源切換解決方案，三電源切換解決方案，燃氣機組解決方案，黑啟動解決方案，高壓機組解決方案，電信基站解決方案，電量保護解決方案，燈塔機組解決方案，雲監控解決方案',
        scheme:'眾智科技專業提供多機並聯解決方案，多機並網解決方案， AMF解決方案，自起動解決方案，CHP解決方案，船舶動力解決方案，應急電源解決方案，同步與負載均分解決方案，水泵機組解決方案，雙電源切換解決方案，三電源切換解決方案，燃氣機組解決方案，黑啟動解決方案，高壓機組解決方案，電信基站解決方案，電量保護解決方案，燈塔機組解決方案，雲監控解決方案等全新的智能化解決方案'
    },
    pinglun:{
        fabiaopinglun:'發表評論',
        shuodianshenme:'說點什麽……',
        quanbupinglun:'全部評論',
        huifu:'回復',
        pinglun:'評論'
    },
    develop:{
        kehutitle:'我們與客戶',
        kehu1:'眾智科技秉承自強不息、眾智成城的企業精神，堅持創新、專註、精益求精；誠信、擔當、合作共贏的企業價值觀，持續探索全新的智能化方案來提升全球行業設備的整體性能。',
        kehu2:'我們以客戶為尊，以市場發展為導向，每年都會在產品研發、標準試驗室、數字工廠方面投入大量資金，為客戶提供超出預期的產品解決方案；',
        kehu3:'公司專家型銷售團隊始終以解決客戶需求為己任，為客戶提供專業、高效、完善的技術服務；超過30%的研發人員時刻待命，為滿足客戶多樣化的產品需求持續努力；',
        gongyingshangtitle:'我們與供應商',
        gongyingshang1:'眾智秉承 「誠信 擔當 合作共贏」的經營理念同供應鏈合作夥伴共同成長，通過數字化技術的應用、緊密的交流合作，協同創新，不斷提升企業和供應鏈的可持續發展能力，在滿足政策監管、合規經營的基礎上，承擔社會責任，減少碳排放對人類環境的影響，眾智科技期待通過和供應鏈夥伴的共同努力，打造綠色、透明、擔當、可持續發展的供應鏈管理體製，使我們的產品和服務能夠從源頭符合環境、社會可持續發展的要求！為社會和人類作出自己的貢獻！',
        yuangongtitle:'我們與員工',
        yuangong1:'員工是眾智最寶貴的財富，眾智與員工彼此信任，勇於擔當，共同創建健康活力的企業氛圍。眾智努力為員工搭建成長與發展的舞臺，充分發揮員工潛能，讓每一位有理想的員工都能盡情施展才華，在實現「百年眾智」的旅途中快樂工作，幸福生活！ ',
        shequtitle:'我們與社區',
        shequ1:'眾智積極承擔社會責任，融入當地社區，通過貢獻教育、支持環保以及誌願、公益活動，踐行著我們對社會可持續發展的承諾。',
        shequ2:'眾智積極發揮我們在人才、技術、資金、文化及管理方面的優勢，攜手社會各界力量，助力創新人才培養為社會發展和文明進步註入源源不斷的創新動力。同時，眾智積極貢獻社會公益事業，做真正負責任的企業公民。',
        huanjingtitle:'我們與環境',
        huanjing1:'眾智致力於環境共生，以再生能源、綠色體系、綠色產品和全流程的綠色運營實踐對環境承諾。秉承持續創新提高能效、降低排放、節約資源、再生能源利用的思想方針進行發展，在產品全生命周期嚴格進行環境管理，確保研發、設計、采購、生產、售後、物流和回收處理的過程符合環境可持續發展的要求，並不斷研發綠色產品及技術，探索運用新技術應對新的環境問題與挑戰。眾智要實現環境共生發展的良性循環， 承擔共建綠水青山的社會責任，鑄就綠色生態圈。'
    }
}