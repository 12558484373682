module.exports = {
    iphone:'móvil',
    lang:'EN',
    langc:'中文',
    lange:'English',
    langh:'繁体',
    name:'SMARTGEN(ZHENGZHOU) TECHNOLOGY CO., LTD.',
    jieshao:'En 1998, iniciamos la investigación y el desarrollo de los primeros controladores de grupos electrógenos y SmartGen se registró y estableció en 2003. SmartGen es una empresa de modernización tecnológica, que se centra en el sistema de control inteligente para motores, generadores y fuentes de alimentación dobles, así como en los productos relacionados, y se concentra en el diseño, la investigación y el desarrollo, la fabricación, la venta y los servicios. Tenemos más de 300 empleados y vendemos productos en todo el mundo. Más del 30% de los empleados se dedican a la I+D, esforzándose por explorar nuevas soluciones inteligentes para mejorar el rendimiento general de los equipos de la industria mundial.',
    manualInChinese:'Manual Chinês',
    englishManual:'Manual Inglês',
    testingSoftware:'Software',
    typicalApplicationDiagram:'Aplicação Típica',
    DModelDiagram:'Diagrama de Modelo 3D',
    chineseSolution:'Chinese Solution',
    englishProgram:'English Solution',
    aSinglePageInChinese:'Chinese single page',
    ProductSinglePage:'Odnostrochnyy produkt',
    chineseCommunicationProtocol:'Protocolo de Comunicação Chinês',
    englishCommunicationProtocol:'Protocolo de Comunicação Inglês',
    shengming:'Declaración: SmartGen adopta los modelos de comercialización de las ventas directas nacionales y fuera del continente agentes designados. Todos los productos se entregan directamente desde la fábrica de Zhengzhou en China.',
    inquire:'Consulta',
    notAvailable:'No hay contenido de búsqueda',
    nosousuo:"No se puede buscar lo que se quiere, quiero comentarios",
    BUG:'quero feedback',
    loginerror:'Please log in first, and then check',
    notLogIn:'Not logged in',
    logIn:'logon',
    close:'close',
    download:'Descargar',
    theFreeCall:'National toll free',
    dataType:'Tipo de Dados',
    all:'Todos',
    getCode:'Get verification code',
    confirm:'Confirm',
    cancel:'Cancel',
    login:{
        zhineng:'¡Deja que el control sea más inteligente!',
        dizao:'Crear un siglo de sabiduría, ser el socio más confiable de la marca',
        zhinengxin:'',
        fendou:'',
        welcome:'Iniciar sesión',
        userName:'Phone or E-mail',
        password:'Senha',
        code:'Captcha',
        logIn:'Logon',
        register:'Registro',
        codelogin:'Iniciar sesión con código de verificación',
        userlogin:'Login with account',
        forgetPassword:'Olvidar la contraseña',
        rememberPassword:'Recordar contraseña',
        tishi:'Kindly reminder: It is common with the APP account of SmartGen products',
        usermsg : 'Username cannot be empty',
        pwdmsg:'Senha cannot be empty',
        codemsg: 'Captcha cannot be empty',
        notnull:'Cannot be empty',
    },
    personal:{
        title:'My Center',
        change:'Change',
        iphone:'Phone number',
        notBound:'Not linked',
        toBind:'link',
        email:'Email address',
        weititle:'It is not linked, you can link to subscribe new products information',
        dyyj:'Subscribe email',
        qxdy:'Cancel subscription',
        changePassword:'Change password',
        loginPassword:'Change your login password',
        closeAnAccount:'Cancel the account',
        deldata:'Delete your account and related data permanently',
        logout:'Cancel',
        changeNickname:'Change nickname',
        replaceAPhoneNumber:'Change phone number',
        codeinput:'Please enter verification code',
        replaceTheEmail:'Change email',
        emailinput:'Please enter your email',
        oldpassword:'Please enter your original password',
        newpassword:'Please enter your new password',
        confirmpassword:'Confirm your new password again',
        modifySuccessfully:'Modify successfully',
        iphonemsg:'Please enter correct phone format',
        emailmsg:'Please enter correct email format'
    },
    layout: {
        search: 'Pesquisa',
        searchplaceholder :'Please input content',
        home:'Índice',
        productcenter:'Productos',
        productClassification:'PRODUCTOS',
        Viewall:'Check all',
        solution:'Soluciones',
        application:'Casos de Aplicación',
        technical:'Suporte técnico',
        download:'Descargar',
        model:'Muestra de producto',
        ECUfaultcode:'Códigos de error de la ECU',
        FAQ:'FAQS',
        Productfeedback:'Comentarios de los usuarios',
        about:'Sobre nós',
        wenhua:'Culture',
        news:'News',
        honor:'Honors',
        logo:'Marca comercial',
        join:'Join Us',
        contact:'Contate-nos',
        store:'Shop',
        cloudplatform:'Cloud Monitoring Platform',
        register:'Register',
        login:'Inicio de sesión',
        userName:'Username',
        exit:'Quit',
        followUs:'Please focus on us',
        chineseWeChatServiceNumber:'Chinese WeChat Service Account',
        englishWeChatServiceNumber:'English WeChat Service Account',
        productAPP:'Aplicativo de produtos SmartGen',
        WeMedia:'SmartGen Mídia',
        sumaitong:'AliExpress Mall',
        taobao:'Taobao Mall',
        ali:'Alibaba Mall',
        a1688:'1688 Mall',
        address:'No.28 Jinsuo Road, cidade de Zhengzhou, província de Henan, China',
        LinkedIn:'LinkedIn platform of SmartGen',
        weibo:'Blog',
        weibourl:'http://blog.smartgen.cn',
        boke:'Facebook',
        bokeurl:'https://www.facebook.com/smartgen98318',
        tencentVideo:'Linkedin',
        tencenturl:'https://www.linkedin.com/company/13719141/admin/',
        Youku:'Twitter',
        Youkuurl:'https://twitter.com/lcsmartgen',
        Tudou:'Odnoklassniki',
        Tudouurl:'https://ok.ru/zhengzhou.smartgen',
        DocIn:'VK',
        DocInurl:'https://vk.com/smartgen980318',
        baidu:'Blogger',
        baiduurl:'http://smartgenblog.blogspot.com/',
        Wordpress:'Wordpress',
        Wordpressurl:'https://smartgenblog.wordpress.com/',
        Tumblr:'Tumblr',
        Tumblrurl:'https://smartgen.tumblr.com/',
        Naver:'Naver',
        Naverurl:'https://blog.naver.com/smartgen980318',
        Site123:'Site123',
        Site123url:'https://5e1ec07d59538.site123.me/',
        YouTube:'YouTube',
        YouTubeurl:'https://www.youtube.com/channel/UCxLKCBUbqLT5mT93j9mzbjQ?view_as=subscriber',
        Pinterest:'Pinterest',
        Pinteresturl:'https://www.pinterest.com/smartgen980318/'
    },
    index:{
        NewProducts:'Nuevos productos',
        xuanze:'Por qué SmartGen',
        dizao:'Construyendo un SmartGen centenario, siendo la marca más confiable para el socio',
        zishen:'Experiencia en la industria',
        lingyu:'Primera generación de desarrollo de productos en 1998, más de 20 años de historia de desarrollo, una de las primeras empresas en China dedicada al desarrollo y producción de grupos electrógenos y ATS de doble potencia.',
        yingyong:'Amplios campos de aplicación',
        yytext:'Los productos pueden aplicarse a la defensa nacional, los centros de datos, las infraestructuras, los barcos, la energía híbrida, las microrredes, la maquinaria de ingeniería, etc.',
        rongyu:'Excelente calificación de certificados',
        tixi:'Los productos han pasado muchas certificaciones de sistemas nacionales y extranjeros, incluyendo CCC, CE, CCS, BV, UL, RS, certificación militar, certificación de laboratorio MTU de Alemania, etc.',
        fengfu:'Varios productos',
        chanpins:'La variedad de productos incluye controladores de grupos eléctricos, controladores de distribución de baja tensión, motores de uso marítimo y controladores de administración de potencia (PMS), controladores de maquinaria pesada, EMS, BMS, PCS, DVR, interruptores de alimentación dual, cargadores de baterías, calentadores del bloque del motor, monitoreo en la nube, módulos de conversión de comunicaciones, módulos de protección de energía eléctrica, módulos de expansión de E/S y otros casi mil tipos diferentes.',
        yanfa:'Fuerte equipo de I+D',
        yongyou:'El equipo profesional de I+D, que representa el 30% del total de empleados, sigue invirtiendo fuertemente en I+D, fabricación inteligente y laboratorio estándar, obteniendo en total casi 600 patentes.',
        zhuanye:'Personal de servicio profesional',
        zhuanyes:'La empresa cuenta con un perfecto sistema de servicio de venta y postventa. Cada región cuenta con un experto en ventas permanentemente destacado en la zona local, responsable de la preventa y la posventa locales.',
        recentNews:'Notícias'
    },
    user:{
        account:'Cuenta',
        verificationCode:'Captcha',
        newPassword:'The new password',
        confirmPassword:'Confirmar contraseña',
        retrievePassword:'Get back Password',
        title:'The password is set successfully, please log in again!',
        futitle:'Please remember the new account password',
        loginImmediately:'Log in now',
        backHomepage:'Back to homepage',
        mimamsg:'The length of the user password must be between 5 and 20',
        reenter:'Please input the password again',
        pwdb:'Two input password are inconsistent'
    },
    reg:{
        mailbox:'E-mail',
        password:'Contraseña',
        confirmPassword:'Confirmar contraseña',
        name:'Nombre',
        mobilePhone:'Teléfono',
        companyName:'Empresa',
        companyIndustry:'Empresa Industria',
        state:'País',
        address:'Dirección',
        register:'Registro',
        registeredSuccessfully:'Registered successfully',
        rememberAccount:'Please remember the account password',
        namemsg:'The nickname of user cannot be empty',
        passwordmsg:'The password of user cannot be empty',
        passwordmsg2:'User password length must be between 5 and 20',
        phonemsg:'Mobile phone number cannot be empty',
        phonemsg2:'Please enter the correct mobile phone number',
        addressmsg:'Address cannot be empty',
        emtail:'Please enter the correct mailbox format',
        reenter:'Please enter password again',
        reenter:'Please input the password again',
        inconformity:'Two input password are inconsistent'
    },
    search:{
        searchResult:'Search result',
        inquire:'Inquire',
        search:'Search',
        dedao:'The results',
        tiao:'Strip',
        all:'whole',
        socialRecruitment:'Social recruitment',
        mobilePhone:'Phone',
        mailbox:'E-MAIL',
        anlititle:'SmartGen cases'
    },
    project:{
        releaseTime:'Release time',
        all:'Todos'
    },
    scheme:{
        planToDownload:'Download da solução',
        solutionOverview:'Program Overview',
        shipin:'Viedo',
        relatedCases:'Casos Relacionados',
        relatedProducts:'Produtos Relacionados'
    },
    product:{
        problemFeedback:'Problem Feedback',
        productOverview:'Descripción del producto',
        technicalParameters:'Parámetros técnicos',
        shipin:'Viedo',
        ziliao:'Descarga de datos',
        relatedCases:'Casos relacionados',
        relevantSolutions:'Related Solutions',
        versionNumber:'Versión',
        downloadLink:'Enlace de descarga',
        WorkingPowerRange:'Rango de potencia de trabajo',
        OverallDimension:'Dimensiones externas',
        InstallationDimension:'Dimensiones de montaje',
        WorkingTemperature:'Temperatura de funcionamiento',
        ProductReferenceFont:'Shrift dlya ssylok na produktsiyu',
        weight:'Peso'
    },
    productinfo:{
        series:'Series'
    },
    ecu:{
        brand:'Brand',
        failureCause:'Fault Cause',
        reactionTitle:'Fault Response',
        ansTitle:'Corrective Action'
    },
    fankui:{
        productModel:'Product Model',
        productModelmsg:'Product model cannot be empty',
        type:'Tipo',
        typeMsg:'Product type cannot be empty',
        function:'Funciones',
        facade:'Apariciones',
        software:' Software',
        hardware:'Hardware',
        else:'Otros',
        describe:'Description',
        describemsg:'Problem description cannot be empty',
        improvementSuggestions:'Sugerencias',
        improvementSuggestionsmsg:'Improvement suggestion cannot be empty',
        mailmsg:'E-mail can not be empty',
        submit:'Enviar',
        operateSuccessfully:'Operation succeeded'
    },
    wenti:{
        problemDescription:'Question Description',
        questionAnswering:'Question Solving',
    },
    ziliao:{
        model:'Type',
        applicationTool:'Herramienta de aplicación',
        name:'Nombre',
        desc:'Descripción del producto',
        dataDownload:'Descargar',
        ziliaoDownload:'Descarga de datos',
        efiDrawings:'Drawing Download'
    },
    jiaru:{
        welfare:'SmartGen welfare',
        jobVacancy:'Job Vacancy',
        Department:'Recruitment department',
        workingPlace:'Place of work',
        Zhengzhou:'Zhengzhou',
        releaseDate:'Release date',
        recruitmentDetails:'Recruitment details',
        salaryAndWelfare:'Compensation and benefits',
        salaryAndWelfareinfo:'Five social insurance and one housing fund and supplementary commercial insurance<br/>Five social insurance and one housing fund and supplementary commercial insurance<br/>Free staff restaurant',
        paidVacation:'Paid holiday',
        paidVacationinfo:'National statutory holidays<br/>Marital leave, maternity leave, breastfeeding leave, paternity leave, bereavement leave, sick leave and annual leave<br/>Give necessary care to employees in case of "childbirth or illness"',
        holidayGift:'Holiday Gifts',
        holidayGiftinfo:'Festival benefits such as Dragon Boat Festival, Mid-Autumn Festival and Spring Festival<br/>Every quarterly daily necessities benefits<br/>Birthday benefits',
        culturalActivity:'Cultural activities',
        culturalActivityinfo:'Company-level activities: anniversary, beer festival, employee family day, annual meeting, etc.<br/>Department-level activities: irregular dinners, tourism, group building, etc.<br/>Associations: Loho Association, Reading Association, Public Welfare Association, Business Etiquette Association',
        talentCultivation:'Personnel Training',
        talentCultivationinfo:'Go out and invite in learning opportunities<br/>Technology, marketing, production, management and other multi-channel career development paths<br/>Excellent Staff Award, Innovation and Improvement Award, Education Promotion Award and Vocational Skills Promotion Award',
        employeeCare:'Employee Care',
        employeeCareinfo:'Occupational health examination<br/>Physical examination of the whole staff<br/>Female employees & special care for employees over 40 years old',
    },
    guanyu:{
        corporateMission:'Misión de la empresa:',
        control:'¡hacer el control más inteligente!',
        spirit:'Visión de la empresa:',
        spiritconetnt:'¡Construir un SmartGen centenario, ser la marca más fiable para el socio!',
        vision:'Valores de la empresa:',
        visionconetnt:'innovación, enfoque, Lean-Better; integridad, responsabilidad, Win-Win.',
        value:'Espíritu empresarial:',
        valueconetnt:'La mejora continua, el trabajo en equipo crea valor.',
        m:'',
        statement:'Declaración medioambiental',
        statementcontent:'Mediante la innovación tecnológica, seguimos estrictamente la norma ISO14001 y otras normas, aplicamos un control y una gestión integrales, y nos proponemos ser una verdadera empresa ecológica.',
        developmentHistory:'Development history',
        contactUs:'Contact us',
        phone:'TEL',
        theForeignTradeInAStraightLine:'Foreign Trade Straight Line',
        fax:'FAX',
        pbx:'Centralita',
        postcode:'Postcode',
        mailbox:'E-mail',
        QQ:'QQ Technical Communication group',
        controlField:'',
        controlField2:'Líder y creador de normas en el control de grupos electrógenos',
        controlFieldy:'23 year',
        agent:'',
        agent2:'Oficinas y agentes en China y en el extranjero',
        agenty:'More than 50',
        patent:'',
        patent2:'Patentes',
        patenty:'398 items',
        user:'',
        user2:'El 30% de los empleados contribuye a la investigación y el desarrollo',
        usery:'More than 300 people',
        annualOutput:'',
        annualOutput2:'Una producción anual de más de 500.000 unidades',
        annualOutputy:'500,000 units',
        informatization:'',
        informatization2:'Superficie total >50.000 metros cuadrados',
        informatizationy:'Industry 4.0',
        researchAndDevelopment:'Fuerza de I+D',
        researchAndDevelopment2:'El equipo profesional de I+D, que ocupa el 30% de los empleados, contribuye a explorar nuevas soluciones inteligentes',
        smart:'Fabricación inteligente',
        smart2:'Perfecta integración e interacción de datos de PLM, MES, WMS y otros sistemas de información con REP como centro de datos',
        quality:'Garantía de calidad',
        quality2:'Una de las principales bases de pruebas de productos en la industria, el producto ha sido sucesivamente certificado por CCC, CE, UL, CCS, BV, etc.',
        market:'Equipo de ventas',
        market2:'Todos los vendedores de SmartGen son expertos que llevan muchos años trabajando en el sector y pueden ofrecer de forma independiente un servicio profesional y eficiente de preventa, venta y postventa',
        laochang:'Jin Suo Lu factory',
        laochangaddress:'No. 28, Jinsuo Road, Zhengzhou high tech Development Zone, Henan Province, China',
        xinchang:'Tian Jian Hu factory',
        xinchangaddress:'No.28 Xuemei Road, Zhengzhou high tech Development Zone, Henhan Province, China',
    },
    biaoshi:{
        title:'El significado de SmartGen',
        content:'SmartGen es nuestra marca registrada en inglés. "Smart" significa ingenioso, inteligente y astuto, "Gen" es la abreviatura de gen-set.',
        standardColors:'STANDARD COLORS',
        brandStandardColor:'Brand standard color',
        levels:'The standard color order',
        auxiliaryColor:'Auxiliary color',
        fuzhu:'In order to ensure the standard color visual unity and coordination in the extended application, according to the different application materials and actual production needs, choose more coordinated with standard color can match',
        mascot:'Mascota',
        jxyy:'Basado en las características del border collie, ya que es astuto, hábil e inteligente, el nombre Smart representa estas características y corresponde a la marca en ingles "SmartGen".',
        annotation:'Significado',
        name:'Llámalo Smart ya que el prototipo viene de border collie, lo que implica espíritus listos e inteligentes.',
        name2:'Con una dirección ligeramente ascendente, las orejas van hacia arriba, Smart está lleno de vigor, lo que implica que SmartGen está lleno de energía y se dirige hacia un futuro próspero.',
        name3:'Los ojos de Smart son brillantes, las cejas son cortas y delgadas, y la boca levanta una sonrisa. Smart está lleno de confianza y está impregnado de energía positiva.',
        logo:'Descarga de SmartGen Logos',
        Download:'Download the document'
    },
    lianxi:{
        placeholder:'',
        companyName:'company name',
        theContact:'Contact',
        website:'Website',
        site:'Endereço'
    },
    seo:{
        title:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        keywords:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        description:'SmartGen is a technological modernization enterprise, focusing on intelligent control system for engine, generator and dual power supply, as well as related products, and concentrating on design, research and development, manufacture, sale and services.Products are widely used in military industry, mining and oil exploration, data centers, ships, intelligent buildings, banks, hospitals, telecommunications, infrastructure and other fields.',
        indextitle:'SmartGen-Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module',
        indexkeywords:'Genset Controller, ATS Controller, Marine Controller, Engine Controller, Battery Charger, Heaters, Monitoring Module, Intelligent Generator Controller,ASM controller,AMF controller,Synchronization controller,Parallel controller,Load share Controller,Lighting tower controller,ATSE,ATS,Power Management System,Pump controller,Fire pump controller,Irrigation controller,Air pump controller,Jacket Heater,Engine Water Heater,Cloud Monitoring Module',
        indexdescription:'SmartGen is a technological modernization enterprise, focusing on intelligent control system for engine, generator and dual power supply, as well as related products, and concentrating on design, research and development, manufacture, sale and services.Products are widely used in military industry, mining and oil exploration, data centers, ships, intelligent buildings, banks, hospitals, telecommunications, infrastructure and other fields.',
        schemetitle:'Solutions - Genset Control Solutions,ATSE Solutions,Marine Control Solutions,Engine Control Solutions ',
        schemekeywords:'Parallel Solution,Lighting Tower Set Solution,Solution for Telecom Base Station,High Voltage Unit Solution,Black Start Solution,ATS Switching Solution,Synchronization and Load Share,Ship Power SolutionPump Unit Solution',
        scheme:'SmartGen striving for Genset Control Solutions,ATSE Solutions,Marine Control Solutions,Engine Control Solutions  to improve the overall performance of global industry equipment.'
    },
    pinglun:{
        fabiaopinglun:'Comments',
        shuodianshenme:'Say something……',
        quanbupinglun:'All comments',
        huifu:'reply',
        pinglun:'comment'
    }
}