import request from '@/utils/request'
//注册
export function reg(data) {
  return request({
    url: 'web/reg',
    method: 'post',
    data
  })
}
// 登录
export function login(data) {
  return request({
    url: 'web/login',
    method: 'post',
    data
  })
}
// 忘记密码
export function forget(data) {
  return request({
    url: 'web/forget',
    method: 'post',
    data
  })
}
// 首页
export function index() {
  return request({
    url: 'web/index',
    method: 'get'
  })
}
// 产品分类，包含系列的下级结构
export function productsClass() {
    return request({
      url: 'web/productsClass',
      method: 'get'
    })
}
// 产品列表（需要传cat_id和分页相关数据）
export function productsList(data) {
    return request({
      url: 'web/productsList',
      method: 'post',
      data
    })
}
// 产品详情
export function productsInfo(id) {
    return request({
      url: 'web/productsInfo/'+id,
      method: 'get'
    })
}
// 解决方案分类
export function schemeClass() {
    return request({
      url: 'web/schemeClass',
      method: 'get'
    })
}
// 解决方案分类下的列表
export function schemeList(data) {
    return request({
      url: 'web/schemeList',
      method: 'post',
      data
    })
}
// 解决方案详情
export function schemeInfo(id) {
    return request({
      url: 'web/schemeInfo/'+id,
      method: 'get'
    })
}
// 应用案例分类
export function projectClass() {
  return request({
    url: 'web/projectclassList',
    method: 'get'
  })
}
// 应用案例列表
export function projectList(data) {
    return request({
      url: 'web/projectList',
      method: 'post',
      data
    })
}
// 应用案例详情
export function projectInfo(id) {
    return request({
      url: 'web/projectInfo/'+id,
      method: 'get'
    })
}
// 下载资料列表
export function downloadList(data) {
    return request({
      url: 'web/downloadList',
      method: 'post',
      data
    })
}
// 下线产品资料列表
export function offdownloadList(data) {
  return request({
    url: 'web/offdownloadList',
    method: 'post',
    data
  })
}
// 下载应用工具
export function toolList() {
  return request({
    url: 'web/toolList',
    method: 'post'
  })
}
// 样册列表
export function catalogueList(query) {
    return request({
      url: 'web/catalogueList',
      method: 'get',
      params:query
    })
}
// ecu故障列表
export function ecuList(data) {
    return request({
      url: 'web/ecuList',
      method: 'post',
      data
    })
}
// faq说明列表
export function faqList(data) {
    return request({
      url: 'web/faqList',
      method: 'post',
      data
    })
}
// 获取验证码
export function getverify() {
  return request({
    url: 'web/getverify',
    method: 'get'
  })
}
// 产品反馈
export function productscollectAdd(data) {
    return request({
      url: 'web/productscollectAdd',
      method: 'post',
      data
    })
}
// 事业部和办事处列表
export function contactusList() {
    return request({
      url: 'web/contactusList',
      method: 'get'
    })
}
// 办事处关联相关人员列表
export function bsUserList(data) {
  return request({
    url: 'web/bsUserList',
    method: 'post',
    data
  })
}
// hr列表
export function hrList(data) {
    return request({
      url: 'web/hrList',
      method: 'post',
      data
    })
}
// 新闻列表
export function newsList(data) {
    return request({
      url: 'web/newsList',
      method: 'post',
      data
    })
}
// 新闻详情
export function getNewsInfo(id) {
  return request({
    url: 'web/newsInfo/'+id,
    method: 'get'
  })
}
// 搜索
export function getSearchList(data) {
  return request({
    url: 'web/searchList',
    method: 'post',
    data
  })
}
// 大事记
export function getHistory() {
  return request({
    url: 'web/getHistory',
    method: 'get'
  })
}
// 用户协议和用户隐私协议
export function getXieyi() {
  return request({
    url: 'web/getXieyi',
    method: 'get'
  })
}
// 获取验证码
export function getCode(data) {
  return request({
    url: 'web/sendCode',
    method: 'post',
    data
  })
}
 // 免密登录
export function codeLogin(data) {
  return request({
    url: 'web/codeLogin',
    method: 'post',
    data
  })
}
 // 通过token获取用户信息
 export function getuserinfo(data) {
  return request({
    url: 'web/getUserinfo',
    method: 'post',
    data
  })
}
 // 修改个人资料
 export function userUpdate(data) {
  return request({
    url: 'web/userUpdate',
    method: 'post',
    data
  })
}
 // 更换/绑定 手机号/邮箱
 export function updateEmail(data) {
  return request({
    url: 'web/updateEmail',
    method: 'post',
    data
  })
}
// 更改密码
export function changePassword(data) {
  return request({
    url: 'web/changePassword',
    method: 'post',
    data
  })
}
// 注销账号
export function logout(data) {
  return request({
    url: 'web/logout',
    method: 'post',
    data
  })
}
// 招聘页面
export function hrPage() {
  return request({
    url: 'web/hrPage',
    method: 'get'
  })
}
// 下载图纸列表
export function tuzhiList(data) {
  return request({
    url: 'web/tuzhiList',
    method: 'post',
    data
  })
}
// 获取评论列表
export function commentList(data) {
  return request({
    url: 'web/commentList',
    method: 'post',
    data
  })
}
//自己评论
export function commentAdd(data) {
  return request({
    url: 'web/commentAdd',
    method: 'post',
    data
  })
}
//评论点赞
export function commentLike(data) {
  return request({
    url: 'web/commentLike',
    method: 'post',
    data
  })
}
// 评论回复
export function commentReplyAdd(data) {
  return request({
    url: 'web/commentReplyAdd',
    method: 'post',
    data
  })
}
//回复点赞
export function commentReplyLike(data) {
  return request({
    url: 'web/commentReplyLike',
    method: 'post',
    data
  })
}

//满意度调查表
export function surveyAdd(data) {
  return request({
    url: 'web/surveyAdd',
    method: 'post',
    data
  })
}

//参数对比
export function canshuduibi(data) {
  return request({
    url: 'web/canshuduibi',
    method: 'post',
    data
  })
}


//发送样册
export function sendYangce(data) {
  return request({
    url: 'web/sendYangce',
    method: 'post',
    data
  })
}

// 投资者关系数据
export function investorDate() {
  return request({
    url: 'web/investorDate',
    method: 'post'
  })
}

// AI对话记录新增
export function addSession(data) {
  return request({
      url: 'websession/add',
      method: 'post',
      data
  })
}

export function getIp() {
  return request({
      url: 'web/getIp',
      method: 'get',
  })
}
